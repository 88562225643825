import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { fetchGig } from "./gig.controller";

const addSavedGig = async ({ gigId, userId }) => {
  try {
    // add gigId to user's savedGigs collection in firebase 9
    const q = query(
      collection(db, "users", userId, "savedGigs"),
      where("id", "==", gigId)
    );
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.docs);
    
    if (querySnapshot.docs.length === 0) {
      const fullGig = await fetchGig(gigId);
      const docRef = await addDoc(
        collection(db, "users", userId, "savedGigs"),
        {
          ...fullGig, id: gigId
        }
      );
      console.log("Document written with ID: ", docRef.id);
    }else{
        console.log("Document already exists");
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const removeSavedGig = async ({ gigId, userId }) => {
  try {
    // remove gigId from user's savedGigs collection in firebase 9
    const docRef = await deleteDoc(
      doc(db, "users", userId, "savedGigs", gigId)
    );
    console.log("Document removed with ID: ", docRef.id);
  } catch (e) {
    console.error("Error removing document: ", e);
  }
};

const getSavedGigs = async ({userId}) => {
  try {
    // fetch gigs from firebase 9 database
    const gigRef = collection(db, "users", userId, "savedGigs");
    const querySnapshot = await getDocs(gigRef);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

export { addSavedGig, removeSavedGig, getSavedGigs };
