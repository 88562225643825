const { collection, query, getDocs, doc, deleteDoc, setDoc, Timestamp } = require("firebase/firestore");
const { db, auth } = require("../FirebaseConfig");

const getVerificationRequests = async () => {
  // fetch gigs from firebase 9 database
  try {
    const gigRef = collection(db, "pendingVerification");
    const q = query(gigRef);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const approveVerification = async (docu) => {
    // delete this data and add it to verified users collection
    try {
        const userRef = doc(db, "verifiedUsers", auth.currentUser.uid);
        const docRef = doc(db, "pendingVerification", auth.currentUser.uid);
        await deleteDoc(docRef);
        await setDoc(userRef, {
            ...docu,
            createdAt: Timestamp.now(),
        });

        
    } catch (error) {
        console.log(error);
    }
};

const rejectVerification = async () => {
    // delete this data and add it to verified users collection
    try {
        const docRef = doc(db, "pendingVerification", auth.currentUser.uid);
        await deleteDoc(docRef);
    } catch (error) {
        console.log(error);
    }
};

export { getVerificationRequests, approveVerification, rejectVerification };
