import { async } from "@firebase/util";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Gig from "../../Components/Gigs/Gig";
import MessageBox from "../../Components/MessageComps/MessageBox";
import MessageList from "../../Components/MessageComps/MessageList/MessageList";
import UserInfo from "../../Components/MessageComps/UserInfo";
import { dummy } from "../../dummy";
import { fetchGig } from "../../Helpers/Controllers/gig.controller";
import { fetchOrdersBySellerId } from "../../Helpers/Controllers/orders.controller";
import { auth } from "../../Helpers/FirebaseConfig";

function MessagePage() {
  const item = dummy[0];
  // const [currentMessage, setCurrentMessage] = useState('');
  const currentMessage = useSelector((st) => st.state.currentMessage) || null;
  const [userListToggle, setUsersListToggle] = useState(true);
  const dispatch = useDispatch();
  const toggleMessageListOnONmobile = () => {  setUsersListToggle(false)};
  const toggleMessageListOffONmobile = () => { setUsersListToggle(true);};
  const [gig, setGig] = useState(null);
  useEffect(()=>{
    try{
      console.log(currentMessage, auth.currentUser?.uid);
      async function abc(){
        if(currentMessage && auth.currentUser){
          setGig(await fetchOrdersBySellerId(currentMessage.users[0] !== auth.currentUser.uid
            ? currentMessage.users[1]
            : currentMessage.users[0]));
          console.log(gig);
        }
      }
      abc();
    }catch(error){
      console.log(error);
    }

  },[currentMessage, auth.currentUser, dispatch])
  return (
    <div className="h-screen overflow-hidden w-screen grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 pt-14">
      {/* <button onClick={() => setUsersListToggle(!userListToggle)} className="block md:hidden absolute right-0 p-2 bg-primary m-2 text-white font-bold rounded ">
        {userListToggle ? "Hide List" : "Show List"}
      </button> */}
      <div className={` ${userListToggle ? "col-span-2" : "hidden"} md:col-span-1 md:block`}>
        <MessageBox toggleMessageListOnONmobile={toggleMessageListOnONmobile} />
      </div>
      <div
        className={`${
          !userListToggle ? "col-span-2" : "hidden"
        } md:block md:col-span-2  h-full overflow-hidden justify-between border`}
      >
        <MessageList toggleMessageListOffONmobile={toggleMessageListOffONmobile}/>
      </div>
      <div className=" hidden lg:flex flex-col pt-20 overflow-y-scroll">
        <Gig
          key={item.id}
          id={item.id}
          title={item.title}
          description={item.description}
          price={item.price}
          images={item.images}
          category={item.category}
          location={item.location}
          seller={item.seller}
          rating={item.rating}
        />
        
      </div>
    </div>
  );
}

export default MessagePage;
