import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";

const createOrder = async ({
  sellerId,
  buyerId,
  deadline,
  sellerAvatar,
  BuyerAvatar, // ! notice the capital B
  price,
  gigId,
  description,
  sellerName,
  buyerName,
  gigTitle,
}) => {
  // create order in firebase 9 database
  const dl = new Date(deadline);
  const deadlineTimestamp = Timestamp.fromDate(dl);
  try {
    const orderRef = collection(db, "orders");
    const newOrder = {
      sellerId,
      buyerId,
      deadline : deadlineTimestamp, 
      sellerAvatar,
      BuyerAvatar,
      price,
      gigId,
      description,
      sellerName,
      buyerName,
      gigTitle,
      startDate: Timestamp.now(),
      status: "pending", // pending, accepted, rejected, completed
    };

    console.log(newOrder);
    return await addDoc(orderRef, newOrder);
  } catch (error) {
    console.log(error);
  }
};

const fetchOrdersBySellerId = async ({ sellerId }) => {
  // fetch orders from firebase 9 database
  try {
    const orderRef = collection(db, "approved_orders");
    const q = query(
      orderRef,
      where("sellerId", "==", sellerId),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const fetchOrdersByBuyerId = async ({ buyerId }) => {
  // fetch orders from firebase 9 database
  try {
    const orderRef = collection(db, "approved_orders");
    const q = query(
      orderRef,
      where("buyerId", "==", buyerId),
    );
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const fetchOrdersByOrderId = async ({ orderId }) => {
  // fetch orders from firebase 9 database
  try {
    const orderRef = collection(db, "orders", orderId);
    const querySnapshot = await getDoc(orderRef);
    return querySnapshot;
  } catch (e) {
    console.log(e);
  }
};

const fetchOrdersByStatus = async ({ status }) => {
  // fetch orders from firebase 9 database
  try {
    const orderRef = collection(db, "approved_orders");
    const q = query(
      orderRef,
      where("status", "==", status),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};  

// on user click accept 
// goto payment page and accept the pay on escrow
// then update the order status to accepted
// then goto the chat page and start the chat



const updateOrder = async ({ id, status }) => {
  // update order in firebase 9 database
  try {
    const orderRef = collection(db, "orders");
    const q = query(orderRef, where("id", "==", id));
    const querySnapshot = await getDocs(q)[0];
    const order = querySnapshot.data();
    // todo create a cloud function that updates the order by aggrement of both parties
    order.status = status;
    addDoc(orderRef, order);
    return true;
  } catch (e) {
    console.log(e);
  }
};

export { createOrder, fetchOrdersByStatus, fetchOrdersBySellerId, fetchOrdersByBuyerId, updateOrder };
