import { Button, Checkbox, Label, Spinner, TextInput } from "flowbite-react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { auth } from "../../Helpers/FirebaseConfig";
import { getGigsBySeller } from "../../Helpers/Controllers/gig.controller";
import { useDispatch, useSelector } from "react-redux";
import { setShowPopup } from "../../app/slices/stateSlice";
import { createOrder } from "../../Helpers/Controllers/orders.controller";
import { convertOrderObjectToStringSeparatedByComma, convertStringSeparatedByCommaToObject } from "../../Helpers/utilityFunctions";
import { createMessage } from "../../Helpers/Controllers/message.controller";

function CreateOffer() {
  const [thisSellersGig, setThisSellersGig] = useState([]);
  const [selectedGigid, setSelectedGigid] = useState(0);
  const [loading, setLoading] = useState(false);
  let buyerInfo = useSelector((s) => s.state.currentMessage);
  
  const dispatch = useDispatch();
  
  const sendTheOffer = async (e) => {
    e.preventDefault();
    if(loading) return;
    setLoading(true);
    buyerInfo = (buyerInfo.usersData[0].id === auth.currentUser?.uid
      ? buyerInfo.usersData[1]
      : buyerInfo.usersData[0]);
      // console.log(buyerInfo.name);
    const offer = {
      sellerId: auth.currentUser.uid,
      sellerName: auth.currentUser.displayName,
      buyerName: buyerInfo.name,
      sellerAvatar: auth.currentUser.photoURL,
      BuyerAvatar: buyerInfo.avatar,
      buyerId: buyerInfo.id,
      gigTitle: thisSellersGig[selectedGigid].data().title,
      description: document.getElementById("co_description").value,
      price: document.getElementById("co_price").value,
      deadline: document.getElementById("co_delivery_time").value,
      location: document.getElementById("co_location").value,
      status: "pending",
      gigId: thisSellersGig[selectedGigid].id,
    };

    // const res = await createOrder(offer);

    const a = convertOrderObjectToStringSeparatedByComma(offer)
    console.log(a);
    await createMessage({
      senderId: auth.currentUser.uid,
      senderName: auth.currentUser.displayName,
      senderAvatar: auth.currentUser.photoURL,
      recieverId: buyerInfo.id,
      recieverName: buyerInfo.name,
      recieverAvatar: buyerInfo.avatar,
      message: a,
      gigId: thisSellersGig[selectedGigid].id,
    })
    // console.log(a);
    // const b = convertStringSeparatedByCommaToObject(a)
    // console.log(b);
    // console.log(b === offer);
    document.getElementById("co_description").value = "";
    document.getElementById("co_price").value = "";
    document.getElementById("co_delivery_time").value = "";
    document.getElementById("co_location").value = "";
    setLoading(false);
    dispatch(setShowPopup(false));


    // console.log(res);
  };

  useEffect(() => {
    try {
      async function getGigsByThisSeller() {
        const gigs = await getGigsBySeller(auth.currentUser.uid);
        setThisSellersGig(gigs);
      }
      getGigsByThisSeller();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <form
      onSubmit={(e) => sendTheOffer(e)}
      className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
    >
      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
        Create an Offer
      </h3>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="co_description" value="Description" />
        </div>
        <textarea
          id="co_description"
          className="w-full rounded border-gray-500"
          placeholder="Describe your gig"
          required={true}
        ></textarea>
      </div>
      <div className="mb-2 flex justify-between items-center">
        <Label htmlFor="co_price" value="Price" />
        <input id="co_price" className="w-20" type="number" required={true} />
      </div>
      <div className="mb-2 flex justify-between items-center">
        <Label htmlFor="co_location" value="Location" />
        <input
          id="co_location"
          className="w-[60%]"
          type="text"
          required={true}
        />
      </div>
      <div className="mb-2 flex justify-between items-center">
        <Label htmlFor="co_delivery_time" value="Deadline" />
        <input
          id="co_delivery_time"
          className="w-40"
          type="date"
          required={true}
        />
      </div>
      <div className=" ">
        <p>Select a Gig</p>
        <div className="flex overflow-x-scroll space-x-2">
          {thisSellersGig ? (
            thisSellersGig.map((gig, index) => {
              const id = gig.id;
              gig = gig.data();
              gig.id = id;
              return (
                <div
                  key={id}
                  onClick={() => {
                    setSelectedGigid(index);
                  }}
                  className={
                    "flex cursor-pointer rounded hover:bg-red-300 duration-200 border-2 flex-col items-center mr-4 " +
                    (index === selectedGigid ? " border-red-700" : "")
                  }
                >
                  <img
                    src={gig.images[0]}
                    alt=""
                    className="w-20 h-20 rounded"
                  />
                  <p>{gig.title}</p>
                </div>
              );
            })
          ) : (
            <div className="flex items-center justify-center">
              <Spinner color="success" aria-label="Success spinner example" />
              <p>Looking for Your Gigs</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-full flex justify-between">
        <button
          type="submit"
          className="bg-primary p-3 mr-2 rounded text-white font-bold w-1/2"
        >
          {loading ? <Spinner/> : "Create Offer"}
        </button>
        <button
          onClick={() => dispatch(setShowPopup(false))}
          className=" bg-red-700 rounded text-white font-bold w-1/2"
        >
          close
        </button>
      </div>
    </form>
  );
}

export default CreateOffer;
