import { httpsCallable } from "firebase/functions";
import { Spinner } from "flowbite-react";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setShowPopup } from "../../app/slices/stateSlice";
import { deleteAccount } from "../../Helpers/Controllers/user.controller";
import { auth, functions } from "../../Helpers/FirebaseConfig";

function DeleteAccPopup() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  return (
    <div>
      <p className="text-2xl">Are you sure you want to delete your account?</p>
      <p className="text-sm">
        This action cannot be undone, and all your earnings will be gone
      </p>
      <div className="flex justify-end mt-5">
        <button
          onClick={() => dispatch(setShowPopup(false))}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            // await deleteAccount();
            if(loading) return;
            setLoading(true)
            const delUser = httpsCallable(functions, "deleteThisUser")
            await delUser({uid: auth.currentUser?.uid});
            window.location.href = "/login";
            setLoading(false)
          }}
          className="bg-red-500 text-white px-4 py-2 rounded-lg"
        >
          {loading ? <Spinner/> : "Delete"}
        </button>
      </div>
    </div>
  );
}

export default DeleteAccPopup;
