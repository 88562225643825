import { Footer } from "flowbite-react";
import React from "react";
import { useDispatch } from "react-redux";
import { setPopupContent, setShowPopup } from "../../../app/slices/stateSlice";
import { auth } from "../../../Helpers/FirebaseConfig";

function FooterComp() {
  const dispatch = useDispatch()
  return (
    <div className="bg-gray-600 text-white p-5 md:p-20 pt-5">
      <div className="w-full text-center ">
        <button
          onClick={() => {
            // redirect to create gig page
            window.location.href = "/createGig";
          }}
          className="bg-white text-primary font-bold p-2 rounded m-2"
        >
          List your Property
        </button>
        <div className="w-full justify-between sm:flex sm:items-center text-white/80 font-light text-sm sm:justify-between">
          {/* <Footer.Brand
            href="https://traveltuk.com"
            src="https://flowbite.com/docs/images/logo.svg"
            alt="travelapp Logo"
            name="traveltuk"
            color="white"
          /> */}
          <div className="logo flex items-center">
            <img
              src="https://flowbite.com/docs/images/logo.svg"
              alt=""
              srcset=""
            />
            <a href="/" className="ml-2 font-bold text-lg">
              TravelTuk
            </a>
          </div>
          <div className="flex lfex-col sm:flex-row">
            <a href="/about" className="ml-2">
              About
            </a>
            <a href="/privacypolicy" className="ml-2">
              Privacy Policy
            </a>
            <a href="/termsandconditions" className="ml-2">
              T&C
            </a>
            <p
              onClick={() => {
                dispatch(setPopupContent("link-to-copy"));
                dispatch(setShowPopup(true))
              }}
              className=" ml-2 cursor-pointer"
            >
              Affiliate
            </p>
            <p
              onClick={() => {
                dispatch(setPopupContent("link-to-copy"));
                dispatch(setShowPopup(true))
              }}
              className=" ml-2 cursor-pointer"
            >
              Refer and earn
            </p>
            <p
              onClick={() => {
                dispatch(setPopupContent("link-to-copy"));
                dispatch(setShowPopup(true))
              }}
              className=" ml-2 cursor-pointer"
            >
              Promotion
            </p>
            <a href={`mailto:rokayaavash@gmail.com`} className="ml-2">
              rokayaavash@gmail.com
            </a>
          </div>
        </div>
        <Footer.Divider />
        <Footer.Copyright href="#" by="traveltuk" year={2022} />
      </div>
    </div>
  );
}

export default FooterComp;
