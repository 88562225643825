/* eslint-disable jsx-a11y/img-redundant-alt */
import { Carousel } from "flowbite-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { addSavedGig } from "../../Helpers/Controllers/savedGIg.controller";
import { auth } from "../../Helpers/FirebaseConfig";
import ProfilePic from "../Avatar/ProfilePic";

function Gig({
  id,
  title,
  description,
  prices,
  images,
  category,
  subCategory,
  location,
  sellerName,
  sellerPicUrl,

  rating,
}) {
  const [heart, setHeart] = React.useState(false);
  let navigate = useNavigate();
  function handleClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    // console.log(event.target.getAttribute("data-value"));
    // if (event.target === event.currentTarget) {
    navigate({
      pathname: "/gigs",
      search: "?id=" + id,
    });
    // }
  }

  async function handleHeartClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    if(!heart){
      addSavedGig({ gigId: id, userId: auth.currentUser?.uid });
    }
    setHeart(!heart);

  }
  return (
    <div className="m-2 border hover:shadow-lg duration-300 bg-white hover:shadow-black/60 rounded">
      <div className="h-56 rounded-t text-center overflow-hidden">
        <Carousel slide={false}>
          {images.map((image, i) => (
            <img src={image} key={i} alt="Gig Image" />
          ))}
        </Carousel>
      </div>
      <div
        className="flex flex-col leading-normal"
        data-value="parent"
        onClick={(e) => handleClicked(e)}
      >
        <div className="flex px-6 pt-3 justify-between">
          <div className="flex">
            <ProfilePic url={sellerPicUrl} />
            <div className="text-sm flex flex-col justify-start">
              <p className="text-gray-900 leading-none mb-0">{sellerName}</p>
              <p className="text-gray-600">{location}</p>
            </div>
          </div>
          <div className="flex items-center">
            <div className=" text-amber-500 flex items-center">
              <span className="text-[30px]">⋆</span>
              <span>{rating}</span>
            </div>
            <span className="text-gray-500 font-light text-sm ml-1">
              {" "}
            </span>
          </div>
        </div>
        <div className="px-6 font-bold">{title}</div>
        <div className="px-6 font-light text-gray-800">
          {description.substring(0, 100)}
          {description.length > 100 ? "..." : ""}
        </div>

        <div className="flex justify-between px-6">
          <div className="flex flex-col ">
            <div className="">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                #{category}
                {" > "}
                {subCategory}
              </span>
            </div>
            <div className=" ">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                {prices && "$" + prices[0]?.price + " - $" + prices[2]?.price}
              </span>
            </div>
          </div>
          <div
            className=""
            onClick={(event) => {
              handleHeartClicked(event);
            }}
          >
            <i
              className={`far fa-heart ${
                heart ? "text-red-600" : "text-gray-500"
              } cursor-pointer text-2xl`}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gig;
