import React from "react";

function MobileNavBar() {
  return (
    <div className="w-full flex md:hidden fixed bottom-0 bg-white h-16 z-30">
      <div className="w-1/4 flex justify-center items-center">
        <a href="/" className="text-center">
          <i className="fa fa-home text-2xl"></i>
          <p className="text-xs">Home</p>
        </a>
      </div>
      <div className="w-1/4 flex justify-center items-center">
        <a href="/posts" className="text-center">
          <i className="fa fa-fire text-2xl"></i>
          <p className="text-xs">Posts</p>
        </a>
      </div>
      <div className="w-1/4 flex justify-center items-center">
        <a href="/messages" className="text-center">
          <i className="fa fa-envelope text-2xl"></i>
          <p className="text-xs">Messages</p>
        </a>
      </div>
      <div className="w-1/4 flex justify-center items-center">
        <a href="/profile" className="text-center">
          <i className="fa fa-user text-2xl"></i>
          <p className="text-xs">Profile</p>
        </a>
      </div>
    </div>
  );
}

export default MobileNavBar;
