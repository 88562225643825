import { Dropdown, Spinner } from "flowbite-react";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { categoriesAndSubcategories } from "../../Helpers/constants";
import { createGig } from "../../Helpers/Controllers/gig.controller";
import { auth } from "../../Helpers/FirebaseConfig";

function CreateGigPage() {
  const [currentPriceIndex, setCurrentPriceIndex] = useState(0);
  const [prices, setPrices] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(
    categoriesAndSubcategories[0].category
  );
  const priceNames = ["Basic", "Standard", "Premium"];
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const savePrice = (e) => {
    e.preventDefault();
    const newPrice = {
      price: document.getElementById("cg_price").value,
      desc: document.getElementById("cg_price_description").value,
    };
    console.log(newPrice);
    // if (priceValue === 0 || priceDescription === "") return;
    setPrices([...prices, newPrice]);
    document.getElementById("cg_price").value = "";
    document.getElementById("cg_price_description").value = "";
    setCurrentPriceIndex(currentPriceIndex < 2 ? currentPriceIndex + 1 : 0);
  };

  const [allGigData, setAllGigData] = useState({
    gigTitle: "",
    gigDescription: "",
    gigCategory: "",
    gigSubCategory: "",
    gigImages: [],
    gigPrices: [],
    location: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      console.log(prices);
      if (prices === []) return;
      setAllGigData({
        gigTitle: document.getElementById("cg_title").value,
        gigDescription: document.getElementById("cg_description").value,
        gigCategory: document.getElementById("cg_category").value,
        gigSubCategory: document.getElementById("cg_subcategory").value,
        prices: prices,
        location: document.getElementById("cg_location").value,
      });
      console.log(document.getElementById("cg_images").value);
      const gigId = await createGig({
        title: document.getElementById("cg_title").value,
        description: document.getElementById("cg_description").value,
        category: document.getElementById("cg_category").value,
        subCategory: document.getElementById("cg_subcategory").value,
        prices: prices,
        location: document.getElementById("cg_location").value,
        images: images,
        sellerId: auth.currentUser.uid,
        sellerName: auth.currentUser.displayName,
        sellerPicUrl: auth.currentUser.photoURL,
      });
      // todo redirect to gig page
      setLoading(false);
      navigate("/gigs?id=" + gigId);
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="[&>*]:flex [&>*]:flex-col mx-10 md:mx-20 pt-20 [&>*]:[&>*]:rounded [&>*]:my-4"
    >
      <p className="text-4xl text-primary font-bold text-center uppercase">
        Create a Gig
      </p>
      <div className="">
        <label htmlFor="title" className="text-lg font-bold text-primary">
          Title
        </label>
        <input id="cg_title" type="text" name="title" required={true} />
      </div>
      <div className="">
        <label htmlFor="description" className="text-lg font-bold text-primary">
          Description
        </label>
        <textarea
          name="description"
          id="cg_description"
          cols="30"
          rows="10"
          required={true}
        ></textarea>
      </div>
      <div className="">
        <div className=" flex flex-col justify-between border-2">
          <div className="h-full ">
            <p className=" font-bold text-center py-2 text-primary text-lg">
              Pricing
            </p>
            <div className="grid grid-cols-3 border">
              {priceNames.map((priceName, index) => (
                <div
                  className={`${
                    currentPriceIndex === index
                      ? "bg-primary text-white font-bold"
                      : "bg-none text-black"
                  } text-center py-2 duration-300 cursor-pointer`}
                  onClick={() => {
                    prices.length > 2 && setCurrentPriceIndex(index);
                  }}
                  key={index}
                >
                  {priceName}
                </div>
              ))}
            </div>
            <div className=" overflow-y-hidden m-4  ">
              <div className="flex items-center">
                <label
                  htmlFor="cost"
                  className="text-lg text-gray-600 whitespace-pre-wrap mr-4"
                >
                  Price
                </label>
                {prices[currentPriceIndex]?.price ? (
                  <p>{prices[currentPriceIndex].price}</p>
                ) : (
                  <input
                    type="number"
                    name="cost"
                    id="cg_price"
                    required={true}
                  />
                )}
              </div>
              <div className="text-lg text-gray-600 whitespace-pre-wrap ">
                <div className="flex flex-col">
                  <label htmlFor="description">Description</label>
                  {prices[currentPriceIndex]?.desc ? (
                    <p>{prices[currentPriceIndex].desc}</p>
                  ) : (
                    <textarea
                      name="description"
                      id="cg_price_description"
                      cols="30"
                      rows="10"
                      required={true}
                    ></textarea>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            className="grid grid-cols-1 border-t-2 mt-2 cursor-pointer"
            onClick={(f) => savePrice(f)}
          >
            <div className="flex items-center justify-center p-3 text-white bg-primary rounded">
              Save
              <i className="fa fa-arrow-right ml-1"></i>
            </div>
          </button>
        </div>
      </div>
      <div className="">
        <label htmlFor="category" className="text-lg font-bold text-primary">
          Category
        </label>
        <select
          name="category"
          id="cg_category"
          onChange={(e) => {
            setCurrentCategory(e.target.value);
          }}
          required={true}
          value={currentCategory}
        >
          {categoriesAndSubcategories.map((cate, index) => (
            <option value={cate.category} key={index}>
              {cate.category}
            </option>
          ))}
        </select>
      </div>
      <div className="">
        <label htmlFor="subcategory" className="text-lg font-bold text-primary">
          Subcategory
        </label>
        <select name="subcategory" id="cg_subcategory" required={true} >
          {currentCategory &&
            categoriesAndSubcategories
              .find((c) => c.category === currentCategory)
              .subcategories.map((scat) => <option key={scat}>{scat}</option>)}
        </select>
      </div>
      <div className="">
        <label htmlFor="location" className="text-lg font-bold text-primary">
          Location
        </label>
        <input type="text" name="location" id="cg_location" required={true} />
      </div>
      <div className="">
        <label htmlFor="images" className="text-lg font-bold text-primary">
          Images
        </label>
        <input
          type="file"
          onChange={(e) => setImages(e.target.files)}
          name="images"
          id="cg_images"
          required={true}
          multiple
          accept="image/*"
        />
      </div>
      <div className="">
        <button type="submit" className="bg-primary p-3 text-white">
          {loading ? <Spinner aria-label="Default status example" /> : "Create"}
        </button>
      </div>
    </form>
  );
}

export default CreateGigPage;
