import { httpsCallable } from "firebase/functions";
import { functions } from "../Helpers/FirebaseConfig";

export const getBalance = async () => {
  try {
    // firebase 9 functions call
    const stp = httpsCallable(functions, "getStripeBalanceNK");
    const res = await stp();
    console.log("res ==> ", res);
    return res;
  } catch (error) {
    alert(`An error occured: ${error.message}`);
  }
};
