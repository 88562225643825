import { Avatar, Dropdown, Navbar } from "flowbite-react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { navbarConst } from "../../Helpers/constants";
import { logOut } from "../../Helpers/Controllers/auth.controller";
import { auth } from "../../Helpers/FirebaseConfig";
import ProfilePic from "../Avatar/ProfilePic";

function CustNavbar() {
  const [small, setSmall] = useState(true);

  // useEffect(() => {
  //   // if current route is /home. only then set small to false
  //     if (typeof window !== "undefined") {
  //       window.addEventListener("scroll", () => {
  //         if (window.location.pathname === "/") {
  //           setSmall(window.pageYOffset > 200);
  //         } else {
  //           setSmall(true);
  //         }
  //       });
  //     }

  //   return () => {
  //     window.removeEventListener("scroll", () =>
  //       setSmall(true)
  //     );
  //   };
  // }, []);
  return (
    <Navbar
      fluid={true}
      rounded={true}
      style={{
        ...(small ? navbarConst.styles.top : navbarConst.styles.bottom),
        transitionDuration: "500ms",
      }}
    >
      <Navbar.Brand href="/">
        <img
          src="https://flowbite.com/docs/images/logo.svg"
          className="mr-3 h-6 sm:h-9"
          alt="Travel app"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          TravelTuk
        </span>
      </Navbar.Brand>
      {auth.currentUser ? (
        <div className="flex md:order-2">
          <Dropdown
            arrowIcon={false}
            inline={true}
            label={<ProfilePic url={auth.currentUser.photoURL} />}
          >
            <Dropdown.Header>
              <span className="block text-sm">
                {auth.currentUser.displayName}
              </span>
              <span className="block truncate text-sm font-medium">
                {auth.currentUser.email}
              </span>
            </Dropdown.Header>
            <a href="/profile">
              <Dropdown.Item>Profile</Dropdown.Item>
            </a>
            <a href="/orders">
              <Dropdown.Item>Orders</Dropdown.Item>
            </a>
            <a href="/savedGigs">
              <Dropdown.Item>Saved Gigs</Dropdown.Item>
            </a>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => logOut()}>Sign out</Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
      ) : (
        <div className="flex items-center md:order-2">
          <a href="/login">
            <button className="btn btn-primary">Login</button>
          </a>
          <Navbar.Toggle />
        </div>
      )}
      <Navbar.Collapse className="text-white">
        <a href="/" className="text-lg font-bold">
          Home
        </a>
        <a href="/posts" className="text-lg font-bold">
          Posts
        </a>
        <a href="/messages" className="text-lg font-bold">
          Messages
        </a>
        <a href={`mailto:rokayaavash@gmail.com`} className="text-lg font-bold">
          Contact
        </a>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustNavbar;
