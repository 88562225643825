import React from "react";
import { getDateFromTimestamp } from "../../../Helpers/utilityFunctions";
import ProfilePic from "../../Avatar/ProfilePic";

function MessageComp({
  message,
  senderName,
  files,
  user,
  timestamp,
  imageUrl,
}) {
  // console.log(message, user, timestamp, imageUrl);
  const currentUser = 1;
  return (
    <div className="message flex my-2 hover:bg-gray-50">
      <div className="image w-[10%] mr-2">
        <ProfilePic url={imageUrl} />
      </div>
      <div className="message max-w-[90%]">
        <div className="top flex">
          <div className="mr-2 font-bold text-sm">
            <a href={"/user?id=" + user}>{senderName}</a>
          </div>
          <div className="date font-light text-sm text-gray-700">
            {getDateFromTimestamp(timestamp)}
          </div>
        </div>
        <div className="message font-normal text-lg  text-gray-700">
          {message}
        </div>
        <div className="files flex">
          {files.map((file) => (
            <img
              onClick={() => {
                window.open(file, "_blank");
              }}
              src={file}
              alt="Unable to load file, click on it to download"
              className=" cursor-pointer w-20 h-20 bg-gray-200 m-1"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MessageComp;
