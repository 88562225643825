import "./App.css";
import Header from "./Components/Header/Header";
import HomePage from "./Pages/Home/HomePage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import MessagePage from "./Pages/Messages/MessagePage";
import { useEffect } from "react";
import AuthenticationPage from "./Pages/Authentication/AuthenticationPage";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./Helpers/FirebaseConfig";
import PostPage from "./Pages/Post/PostPage";
import GigPage from "./Pages/Gigs/GigPage";
import SearchPage from "./Pages/Search/SearchPage";
import CreateGigPage from "./Pages/CreateGig/CreateGigPage";
import CommentsPage from "./Pages/Comments/CommentsPage";
import SavedGigsPage from "./Pages/SavedGigs/SavedGigsPage";
import ProfilePage from "./Pages/Profile/ProfilePage";
import { Footer } from "flowbite-react";
import CustNavbar from "./Components/Header/CustNavbar";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setUserdata } from "./app/slices/stateSlice";
import CustPopup from "./Components/Utility/CustPopup";
import OrdersPage from "./Pages/Orders/OrdersPage";
import OtherUserProfilePage from "./Pages/OtherUserProfile/OtherUser";
import MobileNavBar from "./Components/Header/MobileNavBar";
import AboutPage from "./Pages/Extra/AboutPage";
import PrivacyPolicyPage from "./Pages/Extra/PrivacyPolicyPage";
import TermsAndCondition from "./Pages/Extra/TermsAndCondition";
import Refer from "./Pages/ReferralPage/Refer";
import AdminPage from "./Pages/Admin/AdminPage";

function App() {
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);
  // console.log(user);
  // if (!user && !loading) {
  //   window.location.href = "/login";
  // }

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //       const uid = user.uid;
  //       dispatch(
  //         setUserdata({
  //           userId: user.uid,
  //           userName: user.displayName,
  //           userEmail: user.email,
  //           userPhotoUrl: user.photoURL,
  //           userPhoneNumber: user.phoneNumber,
  //           createdAt: user.metadata.createdAt,
  //         })
  //       );
  //       window.location.href = "/";
  //       console.log(
  //         "we can put dispatch here : ",
  //         user.uid,
  //         user.displayName,
  //         user.email,
  //         user.photoURL,
  //         user.phoneNumber,
  //         user.metadata.createdAt
  //       );

  //       // ...
  //     } else {
  //       // User is signed out
  //       // ...
  //       dispatch(setUserdata(null));
  //       window.location.href = "/login";
  //       console.log("we can put dispatch here : ", "user is signed out");
  //     }
  //   });
  // }, []);

  return (
    <Router>
      <div className="App">
        <CustNavbar />
        {/* {user && <CustNavbar />} */}
        <MobileNavBar/>
        <CustPopup/>
        {/* <HomePage /> */}
        <Routes>
          {/* <Route
            path="/"
            element={user ? <HomePage /> : !loading && <AuthenticationPage />}
          /> */}
          <Route
            path="/"
            element={ <HomePage />}
          />
          <Route path="/messages" element={<MessagePage />} />
          <Route path="/posts" element={<PostPage />} />
          <Route path="/login" element={<AuthenticationPage />} />
          <Route path="/gigs" element={<GigPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/createGig" element={<CreateGigPage />} />
          <Route path="/savedGigs" element={<SavedGigsPage />} />
          <Route path="/post" element={<CommentsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/user" element={<OtherUserProfilePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/termsandconditions" element={<TermsAndCondition />} />
          <Route path="/refer" element={<Refer />} />
          {/* Todo disable all writes to user, and other collections on rules */}
          {auth.currentUser?.email === "rokayaavash@gmail.com" &&  <Route path="/admin" element={<AdminPage />} />}

          <Route
            path="*"
            element={
              <div className="grid h-screen place-items-center text-6xl text-gray-500 font-extrabold">
                Not Found
              </div>
            }
          />
        </Routes>
        <div  className="h-20 md:h-0">
          </div>
      </div>
    </Router>
  );
}

export default App;
