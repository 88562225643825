import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  userdata: null,
  currentMessage: null,
  showPopup: false,
  popupMessage: null,
  currentReferralUid: null,
};

// const updateCurrentMessage = ({messageId}) => {
//   return {
//     type: 'state/updateCurrentMessage',
//     payload: {
//       messageId
//     }
//   }
// }

export const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    setUserdata: (state, action) => {
      state.userdata = action.payload;
    },
    setCurrentMessage: (state, action) => {
      state.currentMessage = action.payload;
    },
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setPopupContent: (state, action) => {
      state.popupMessage = action.payload;
    },
    setCurrentRefferalUid: (state, action) => {
      state.currentReferralUid = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  setUserdata,
  setCurrentMessage,
  setPopupContent,
  setShowPopup,
  setCurrentRefferalUid,
} = stateSlice.actions;

export default stateSlice.reducer;
