import { httpsCallable } from "firebase/functions";
import { functions } from "../Helpers/FirebaseConfig";

// if user is verified, show payment button else show verify button

export const verifySellerPayout = async () => {
  try {
    // firebase 9 functions call
    const payoutForm = httpsCallable(functions, "customerOnboardingForm");
    const res = await payoutForm({
      refresh_url: window.location.origin + "/orders",
      return_url: window.location.origin + "/orders",
    });
    console.log("res ==> ", res);
    return res;
  } catch (error) {
    alert(`An error occured: ${error.message}`);
  }
};
