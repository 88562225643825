import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUserdata } from "../../app/slices/stateSlice";
import FormAction from "../../Components/Forms/FormAction";
import Input from "../../Components/Input/Input";
import { signUpWithEmailAndPw } from "../../Helpers/Controllers/auth.controller";
import { signupFields } from "./formFields";

const fields = signupFields;
let fieldsState = {};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Signup() {
  const [signupState, setSignupState] = useState(fieldsState);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const handleChange = (e) =>
    setSignupState({ ...signupState, [e.target.id]: e.target.value });

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const ret = await signUpWithEmailAndPw(
        signupState["email-address"],
        signupState["password"],
        signupState["First-Name"],
        signupState["Last-Name"],
        image
      );
      setLoading(false);
      // dispatch(setUserdata(ret))
      console.log(ret);
    }
    // createAccount();
  };

  //handle Signup API Integration here
  const createAccount = () => {};

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <div className=" overflow-y-scroll">
        {fields.map((field) => (
          <Input
            key={field.id}
            handleChange={handleChange}
            value={signupState[field.id]}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            name={field.name}
            type={field.type}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
          />
        ))}
        <p>Profile</p>
        <input type="file" required={true} onChange={e => setImage(e.target.files[0])} accept="image/*" id="avatar" name="avatar" className=" border-2" placeholder="Select Profile" />
        <FormAction
          loading={loading}
          handleSubmit={handleSubmit}
          text="Signup"
        />
      </div>
    </form>
  );
}
