import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { Alert } from "flowbite-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserdata } from "../../app/slices/stateSlice";
import { app, db } from "../FirebaseConfig";
import { uploadFile } from "./files.controller";

const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

const auth = getAuth();

const addUserDetailsInCollection = async (user) => {
  const userRef = doc(db, "users", user.uid);
  const userSnap = await setDoc(userRef, {
    email: user.email,
    name: user.displayName,
    photoURL: user.photoURL,
    uid: user.uid,
    createdAt: user.metadata.creationTime,
    lastLoginAt: user.metadata.lastSignInTime,
    affiliateId: window.localStorage.getItem("affiliateId") || "",
  });
  return userSnap;
};

const googleSignIn = async () => {
  // console.log("googleSignIn");
  // signInWithPopup(auth, provider)
  //   .then((result) => {
  //     const credential = GoogleAuthProvider.credentialFromResult(result);
  //     const token = credential.accessToken;
  //     const user = result.user;
  //     // setDoc(doc(db, "users", user.uid), {
  //     //       email: user.email,
  //     //       displayName: user.displayName,
  //     //       profilePic: user.photoURL,
  //     //     }, {merge: true}).then((docRef) => {
  //     //       console.log("Document written with ID: ", docRef.id);
  //     //     })
  //     //     .catch((error) => {
  //     //       console.error("Error adding document: ", error);
  //     //     });
  //     addUserDetailsInCollection(user).then((docRef) => {
  //       console.log("Document written with ID: ", docRef.id);
  //     })
  //     .catch((error) => {
  //       console.error("Error adding document: ", error);
  //     });
  //     window.location.href = "/";
  //     return { user, token };
  //   })
  //   .catch((error) => {
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     const email = error.email;
  //     const credential = GoogleAuthProvider.credentialFromError(error);
  //     console.log(errorCode, errorMessage, email, credential);
  //     alert(errorMessage);
  //     return { errorCode, errorMessage, email, credential };
  //   });


  // const user = auth.currentUser
  //     setDoc(doc(db, "users_data", "user.uid"), {
  //           email: user.email,
  //           displayName: user.displayName,
  //           profilePic: user.photoURL,
  //         }).then((docRef) => {
  //           console.log("Document written with ID: ", docRef.id);
  //         })
  //         .catch((error) => {
  //           console.error("Error adding document: ", error);
  //         });

  // change above code to async await

  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const user = result.user;
    await addUserDetailsInCollection(user);
    // const nu = await setDoc(doc(db, "users", user.uid), {
    //   email: user.email,
    //   displayName: user.displayName,
    //   profilePic: user.photoURL || "",
    // });
    // console.log(nu);
    window.location.href = "/";
    return { user, token };
  } catch (e) {
    const errorCode = e.code;
    const errorMessage = e.message;
    const email = e.email;
    const credential = GoogleAuthProvider.credentialFromError(e);
    console.log(errorCode, errorMessage, email, credential);
    alert(errorMessage);
    return { errorCode, errorMessage, email, credential };
  }
};

const faceBookSignIn = () => {
  console.log("faceBookSignIn");
};

const signUpWithEmailAndPw = async (
  email,
  password,
  firstName,
  lastName,
  profilePic
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    if (user) {
      const file = profilePic;
      const path = `profilePics/${user.uid}/${
        file.name + Date.now().toString()
      }`;
      const snapshot = await uploadFile(file, path);
      const url = await getDownloadURL(snapshot.ref);
      const nuser = await updateProfile(user, {
        displayName: firstName + " " + lastName,
        photoURL: url,
      });
      // const saveUserInCollection = await addDoc(collection(db, "users"), {
      //   id: user.uid,
      //   email: user.email,
      //   displayName: firstName + " " + lastName,
      //   profilePic: url,
      // }, {merge: true});
      const saveUserInCollection = await addUserDetailsInCollection(user);
      // const ssl = await sendSlToEm(user.email);
      // console.log(saveUserInCollection);
      window.location.href = "/";
    }
    return user;
  } catch (e) {
    const errorCode = e.code;
    const errorMessage = e.message;
    alert("error " + errorMessage);
    return { errorCode, errorMessage };
  }
};

const signinWithEmailAndPw = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    // const nu = await setDoc(doc(db, "users", user.uid), {
    //   email: user.email,
    //   displayName: user.displayName,
    //   profilePic: user.photoURL,
    // }, {merge: true});
    await addUserDetailsInCollection(user);
    window.location.href = "/";
    return user;
  } catch (e) {
    const errorCode = e.code;
    const errorMessage = e.message;
    alert("error " + errorMessage);
    return { errorCode, errorMessage };
  }
};

const logOut = () => {
  // redirect to login using window
  auth.signOut();
  window.location.href = "/login";
};

// const authController = {};


const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: window.location.href,
    // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'example.page.link'
};

const sendSlToEm = async (email) => {
  console.log(email)
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    alert("Email sent");
  } catch (e) {
    const errorCode = e.code;
    const errorMessage = e.message;
    console.log(errorCode, errorMessage);
    alert("error " + errorMessage);
    return { errorCode, errorMessage };
  }
}

export { googleSignIn, signUpWithEmailAndPw, logOut, signinWithEmailAndPw, sendSlToEm };
