import React, { useEffect } from "react";
import { auth, db } from "../../../Helpers/FirebaseConfig";
import {
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { messagesForThisUser } from "../../../Helpers/dummyData";
import MessageComp from "./MessageComp";
import UserHeader from "../../Header/UserInfoHeader";
// import { dummyGig } from "../../../dummy";
import {
  createMessage,
  getMessagesByRoom,
} from "../../../Helpers/Controllers/message.controller";
import { fetchGig } from "../../../Helpers/Controllers/gig.controller";
import { useDispatch, useSelector } from "react-redux";
import { setPopupContent, setShowPopup } from "../../../app/slices/stateSlice";
import OrdersComponent from "../../Utility/OrderComponent";
import { convertStringSeparatedByCommaToObject } from "../../../Helpers/utilityFunctions";
import { Spinner } from "flowbite-react";

function MessageList({ toggleMessageListOffONmobile }) {
  const [messages, setMessages] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  // const [gig, setGig] = React.useState(null);
  const currentMessage = useSelector((st) => st.state.currentMessage) || null;
  const dispatch = useDispatch();
  const messagesEndRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  // onSnapshot(collection(db, "messages", currentMessage.id, "messages"), (snapshot) => {
  //   console.log(snapshot.docs, "snapshot.docs");
  // setMessages(snapshot.docs);
  // snapshot.docChanges().forEach((change) => {
  //   if (change.type === "added") {
  //     // console.log("New city: ", change.doc.data());
  //     if(!change.doc.data()) return;
  //     setMessages((prev) => [...prev, change.doc.data()]);
  //   }
  //   if (change.type === "modified") {
  //     setMessages((prev) => {
  //       const newMessages = prev.map((message) => {
  //         if (message.id === change.doc.data().id) {
  //           return change.doc.data();
  //         }
  //         return message;
  //       });
  //       return newMessages;
  //     });
  //   }
  //   if (change.type === "removed") {
  //     // console.log("Removed city: ", change.doc.data());
  //     setMessages((prev) => {
  //       const newMessages = prev.filter(
  //         (message) => message.id !== change.doc.data().id
  //       );
  //       return newMessages;
  //     });
  //   }
  // });
  // });

  useEffect(() => {
    try {
      async function abc() {
        if (currentMessage) {
          setMessages(await getMessagesByRoom(currentMessage.id));
          // setGig(await fetchGig(currentMessage.lastMessage.gigId));
        }
        // console.log(message.data());
      }
      abc();
      const q = query(
        collection(db, "messages", currentMessage.id, "messages"),
        orderBy("createdAt", "asc")
      );
      onSnapshot(q, (snapshot) => {
        setMessages(snapshot.docs);
      });
    } catch (error) {
      console.log(error);
    }
  }, [currentMessage, dispatch]);

  const submitMessage = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    const message = document.getElementById("msg_input").value;
    const m = await createMessage({
      message,
      senderId: auth.currentUser.uid,
      senderName: auth.currentUser.displayName,
      senderAvatar: auth.currentUser.photoURL,
      files,
      // gigId: currentMessage.lastMessage.gigId,

      // we need both user id to see if the message link has already been established
      recieverId:
        currentMessage.users[0] === auth.currentUser.uid
          ? currentMessage.users[1]
          : currentMessage.users[0],
      roomId: currentMessage.id,
    });
    // setMessages(await getMessagesByRoom(currentMessage.id));
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    document.getElementById("msg_input").value = "";
    setLoading(false);
  };

  const showOfferCreationPopup = () => {
    dispatch(setPopupContent("create-offer"));
    dispatch(setShowPopup(true));
  };
  return messages ? (
    <div className="col-span-2 flex flex-col h-full justify-between border">
      <div className="p-4 pb-0 border-b-2 flex items-end h-[10%] w-full col-span-6">
        {currentMessage && (
          <div className="flex">
            <i
              className="fas fa-arrow-left text-2xl mr-4 cursor-pointer"
              onClick={() => toggleMessageListOffONmobile()}
            ></i>
            <UserHeader currentMessage={currentMessage} />
          </div>
        )}
      </div>
      <div className=" flex flex-col h-[90%] px-4 overflow-y-scroll col-span-6">
        {messages ? (
          messages.map((message, i) => {
            const id = message.id;
            message = message.data();
            message.id = id;
            const isOrder =
              message.message.split("<8=8=8=8> ")[0].split(" ")[0] ===
              "sellerId";
            if (isOrder) {
              const order = convertStringSeparatedByCommaToObject(
                message.message
              );
              return (
                <OrdersComponent
                  key={i}
                  {...{
                    ...order,
                    messageId: message.id,
                    messageRoomId: currentMessage.id,
                  }}
                />
              );
            }
            return (
              <MessageComp
                key={i}
                message={message.message}
                user={message.senderId}
                timestamp={message.createdAt.seconds}
                imageUrl={message.senderAvatar}
                files={message.files}
                senderName={message.senderName}
              />
            );
          })
        ) : (
          <p className="text-center">No messages yet</p>
        )}
        <div ref={messagesEndRef} className="h-0 w-0"></div>
      </div>
      <form
        onSubmit={(e) => submitMessage(e)}
        className="flex items-center justify-end h-[10%] col-span-6"
      >
        <input
          type="text"
          required={true}
          id="msg_input"
          placeholder="Type a message"
          className="w-3/4 h-10 rounded-l border-2 border-r-none pl-2 border-gray-300"
        />
        {/* file Selector */}
        <input type="file" className="hidden" />
        <div>
          <label htmlFor="file">
            <i className="fas fa-paperclip text-2xl text-gray-400 mx-2 hover:text-gray-500 cursor-pointer"></i>
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => setFiles(e.target.files)}
            required={false}
            multiple
            className="hidden"
          />
        </div>
        <p
          onClick={(e) => showOfferCreationPopup()}
          className="bg-primary pt-2 text-center font-bold text-white mx-2 w-1/4 h-10"
        >
          Create Offer
        </p>
        <button
          type="submit"
          className="bg-primary font-bold text-white rounded-r w-1/4 h-10"
        >
          {loading ? <Spinner /> : "Send"}
        </button>
      </form>
    </div>
  ) : (
    <h1 className=" col-span-2 row-span-6 grid place-items-center text-4xl text-gray-600 font-extrabold">
      Select a message to display
    </h1>
  );
}

export default MessageList;
