import { httpsCallable } from "firebase/functions";
import { functions } from "../Helpers/FirebaseConfig";

export const markOrderCompleted = async ({ orderId }) => {
  try {
    // firebase 9 functions call
    const paySeller = httpsCallable(functions, "makeStripePaymentToSeller");
    const res = await paySeller({
      orderId,
    });
    console.log("res ==> ", res);
    return res;
  } catch (error) {
    alert(`An error occured: ${error.message}`);
  }
};
