import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { db } from "../FirebaseConfig";
import { uploadFile } from "./files.controller";

const createGig = async ({
  title,
  description,
  location,
  category,
  subCategory,
  images = [],
  sellerId,
  sellerName,
  sellerPicUrl,
  prices,
}) => {
  // add gig to firebase 9 database
  const gig = {
    title,
    description,
    location,
    category,
    subCategory,
    images,
    sellerId,
    sellerName,
    sellerPicUrl,
    prices,
    createdAt: Timestamp.now(),
  };
  try {
    const imageLinks = [];
    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const path = `gigs/${title}/${file.name + Date.now().toString()}`;
      const snapshot = await uploadFile(file, path);
      const url = await getDownloadURL(snapshot.ref);

      imageLinks.push(url);
      // gig.images[i] = snapshot.ref.fullPath;
    }
    gig.images = imageLinks;
    // const imageRef = await uploadFile(images, `gigs/${title.split(" ").join('-')}/images/${images.name}`);
    const docRef = await addDoc(collection(db, "gigs"), gig);
    // console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    // todo: show a popup for the error
    console.log(e);
  }
};

const fetchGigsBySearchTerm = async ({
  category = "",
  subCategory = "",
  location = "",
  titleOrDescription = "",
  sellerName = "",
}) => {
  // fetch gigs from firebase 9 database
  try {
    const gigRef = collection(db, "gigs");
    const q = query(
      gigRef,
        // category && where("category", "==", category),
        //  where("description", "==", titleOrDescription),
      //   sellerName && where("sellerName", "==", sellerName),
      location && where("location", "==", location)
      //   subCategory && where("subCategory", "==", subCategory)
    );
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.docs);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const fetchGig = async (id) => {
  // fetch gig from firebase 9 database
  try {
    const gig = await getDoc(doc(db, "gigs", id));
    // return querySnapshot.docs[0].data();
    // console.log(gig.data());
    return gig.data();
  } catch (e) {
    console.log(e);
  }
};

const getGigsBySeller = async (sellerId) => {
  // fetch gigs from firebase 9 database
  try {
    const gigRef = collection(db, "gigs");
    const q = query(gigRef, where("sellerId", "==", sellerId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const updateGig = async (id, gig) => {
  // update gig in firebase 9 database
};

const deleteGig = async (id) => {
  // delete gig from firebase 9 database
  try {
    const docRef = doc(db, "gigs", id);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
  }
};

const getGigByCategory = async ({category}) => {
  // fetch gigs from firebase 9 database
  try {
    const gigRef = collection(db, "gigs");
    const q = query(gigRef, where("category", "==", category));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const getfourtyGigs = async () => {
  // fetch gigs from firebase 9 database
  try {
    const gigRef = collection(db, "gigs");
    const q = query(gigRef);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

export {
  createGig,
  fetchGigsBySearchTerm,
  getGigsBySeller,
  fetchGig,
  updateGig,
  deleteGig,
  getGigByCategory,
  getfourtyGigs,
};
