import { Spinner } from "flowbite-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PostComponent from "../../Components/Posts/PostComponent";
import { dummyPost } from "../../dummy";
import { categoriesAndSubcategories } from "../../Helpers/constants";
import {
  createPost,
  getAllPosts,
} from "../../Helpers/Controllers/post.controller";
import { auth } from "../../Helpers/FirebaseConfig";

function PostPage() {
  const [images, setImages] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const newPost = async (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      let id = await createPost({
        title: document.getElementById("pst_title").value,
        images: images,
        category: document.getElementById("pst_category")?.value || "other",
        subCategory: document.getElementById("pst_subcategory")?.value || "other",
        location: document.getElementById("pst_location")?.value || "somewhere",
        sellerId: auth.currentUser.uid,
        sellerName: auth.currentUser.displayName,
        sellerImage: auth.currentUser.photoURL,
      });

      id = id.id;

      document.getElementById("pst_title").value = "";
      // document.getElementById("pst_category")?.value = "";
      // document.getElementById("pst_subcategory")?.value = "";
      document.getElementById("pst_location").value = "";
      setPosts(await getAllPosts());
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchPosts() {
      setPosts(await getAllPosts());
      // console.log(posts[0].data());
    }
    fetchPosts();
  }, []);
  return (
    <div className="w-screen bg-gray-50 pt-20 grid grid-cols-5">
      
      {auth.currentUser && <PostCreate 
      images={images}
      setImages={setImages}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      newPost={newPost}
      loading={loading}
      />}
      <div className=" col-span-5 md:col-span-3 md:overflow-scroll max-h-screen">
        {posts.map((post) => {
          const id = post.id;
          post = post.data();
          post.id = id;
          // console.log("id", post.id)
          return <PostComponent key={post.id} {...post} />;
        })}
      </div>
    </div>
  );
}

const PostCreate = ({
  images,
  setImages,
  currentCategory,
  setCurrentCategory,
  newPost,
  loading,
}) => {
  return (
    <div className=" col-span-5 md:col-span-2 right-0 top-0 p-10">
        <form
          onSubmit={(e) => newPost(e)}
          className=" bg-white p-2 rounded-md shadow-lg"
        >
          <p className=" text-2xl font-bold text-center">create a post</p>
          <label className=" text-xs text-gray-900 font-bold">Title</label>
          <textarea
            id="pst_title"
            required={true}
            placeholder="Whats on your mind"
            className="w-full shadow-md rounded-lg p-2"
          ></textarea>
          <div className="icons text-end">
            <input
              type="file"
              onChange={(e) => setImages(e.target.files)}
              name="images"
              id="pst_images"
              required={true}
              multiple
              accept="image/*"
            />
            <i className="fa fa-image "></i>
            <i className="fa fa-video-camera mx-2"></i>
          </div>

          <label className="text-xs text-gray-900 font-bold">Location</label>
          <input
            type="text"
            required={true}
            id="pst_location"
            placeholder="Enter Location"
            className="w-full shadow-md rounded-lg p-2"
          />
          {/* <div className="">
            <label
              htmlFor="category"
              className="text-lg font-bold text-primary"
            >
              Category
            </label>
            <select
              name="category"
              id="pst_category"
              onChange={(e) => {
                setCurrentCategory(e.target.value);
              }}
              required={true}
            >
              {categoriesAndSubcategories.map((cate, index) => (
                <option value={cate.category} key={index}>
                  {cate.category}
                </option>
              ))}
            </select>
          </div> */}
          {/* <div className="">
            <label
              htmlFor="subcategory"
              className="text-lg font-bold text-primary"
            >
              Subcategory
            </label>
            <select name="subcategory" id="pst_subcategory" required={true}>
              {currentCategory &&
                categoriesAndSubcategories
                  .filter((c) => c.category === currentCategory)[0]
                  .subcategories.map((scat) => (
                    <option key={scat}>{scat}</option>
                  ))}
            </select>
          </div> */}
          <button
            type="submit"
            className="bg-primary text-white w-full py-2 rounded-lg mt-2"
          >
            <i className="fa fa-lock"></i> {loading ? <Spinner /> : "Post"}
          </button>
        </form>
      </div>
  )
                  }

export default PostPage;
