import React from "react";
import { Dropdown } from "flowbite-react";
import { categoriesAndSubcategories } from "../../Helpers/constants";
import { useState } from "react";


function SearchFilter() {
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentSubCategory, setCurrentSubCategory] = useState("");
  return (
    <div className="flex flex-col md:flex-row justify-between m-4">
      <p className="text-lg font-bold text-white">Filter</p>
      <div className="filters flex sm:flex-row flex-col">
        <div className="categories">
          <Dropdown label={currentCategory ? currentCategory : "Catrgories"} style={{backgroundColor: "transparent", color: "white", border: "2px solid white", margin: "0 0rem" }}>
            {categoriesAndSubcategories.map((cat) => (
              <Dropdown.Item onClick={() => {setCurrentCategory(cat.category)}} key={cat.category}>{cat.category}</Dropdown.Item>
            ))}
          </Dropdown>
        </div>
        <div className="Sub Categories">
          <Dropdown disabled={!currentCategory} label={currentSubCategory ? currentSubCategory : "subCatrgories"} style={{backgroundColor: "transparent", color: "white", border: "2px solid white", margin: "0 1rem" }}>
            {currentCategory && categoriesAndSubcategories.filter(c => c.category === currentCategory)[0].subcategories.map((scat) => (
              <Dropdown.Item 
                onClick={() => {setCurrentSubCategory(scat)}}
              key={scat}>{scat}</Dropdown.Item>
            ))}
          </Dropdown>
        </div>
        <div className="Budget">
          <Dropdown label="Budged" style={{backgroundColor: "transparent", color: "white", border: "2px solid white", margin: "0 0rem" }}>
            <Dropdown.Item>Dashboard</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Item>Earnings</Dropdown.Item>
            <Dropdown.Item>Sign out</Dropdown.Item>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;
