import React from 'react'

function AboutPage() {
  return (
    <div className='mx-5 py-20 md:mx-20 text-gray-700 font-light font-md whitespace-pre-wrap' >{` WHO ARE WE?
    Travel Guide.apk, is a boutique Destination Management Company (DMC) with a stellar reputation for making dreams come true!
    we provide a wide range of tailored services from the ultimate VIP experience, accommodation bookings, guided tours and excursions to all of our islands’ hidden gems. With life being so hectic, at Ocean Blue Travel, we firmly believe that holidays are necessary for one’s wellbeing; this is why we plan your vacation from A-Z with great care, paying attention to even the smallest details! We are uniquely competent in realizing your dream holiday and endeavor to show you the best of Seychelles!
    Bask in the tropical sun and let the waves wash away your cares whilst our travel professionals cater to your perfect get-away; ensuring value, safety and service of the highest quality.
    OUR STORY
    Inspired by the gentle lapping waves on the sandy shores, and the serene calmness of the untouched, green forests of the Seychelles; our story is a simple tale of love for our island home. With hospitality being an essential component of our Seychellois DNA, providing an unforgettable experience to our visitors is a natural corollary. Abundantly blessed with natural beauty, we aim to provide a comprehensive service that enables travelers to savor the unique possibilities our islands have to offer and to do so in a manner that is wholesome and hassle-free. All these combinations have fueled the passion, propelling us on this exciting journey where we get to share a piece of our paradise with those who seek experiences of a lifetime!
    OUR MISSION
    To consistently provide personalized services of the highest quality through our “savoir faire” and personal touch that make us stand out from the rest.
    OUR PROMISE
    To meet the requisites of the dynamic tourism industry while maintaining the right blend of sustainable living, the protection of our environment and the promotion of our culture that makes us who we are.
    WHY TRAVEL WITH US?
    
    Professional
    Our team has more than a decade of combined experience in the tourism sector with excellent customer service. We know our islands inside-out and will provide you with the unique Seychelles experience.
    
    Unforgettable experiences
    Our wide variety of activities and experiences cater to different interests. Whether you’re travelling solo, as a family or a large group; we’ve got you covered!
    
    Unmatched luxury
    We take pride in offering the best luxury packages in Seychelles, allowing you to travel in comfort and access VIP privileges.
    Competitive rates
    We offer the best competitive rates on the island and cater to different budgets. Contact us  for your customized itinerary.
    24/7 Service
    Our team is available round the clock to cater to your needs and travel plans adjustments through flexibility and creative solutions.
    
    + 1000 Happy Customers 
    Offers Premium tours & activities
     H24 Availability 
    Multilingual Team`}</div>
  )
}

export default AboutPage