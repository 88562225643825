import React from 'react'

function Comments({commenterId, commenterName, comment, createdAt, location, commenterAvatar}) {
  return (
    <div className="message flex py-4 hover:bg-gray-100">
        <div className="image w-[10%]">
            <img className=" h-11 w-11 bg-cover rounded-[50%] mx-2" src={commenterAvatar} alt="" />
        </div>
        <div className="message max-w-[90%]">
            <div className="top flex">
                <div className="mr-2 font-bold text-sm">
                    <a href="/user">{commenterName}</a>
                </div>
                <div className="date font-light text-sm text-gray-700">{createdAt ? new Date(createdAt?.seconds).toDateString() : "some time ago"}</div>
            </div>
            <div className="message font-normal text-lg  text-gray-700">{comment}</div>
        </div>
    </div>
  )
}

export default Comments