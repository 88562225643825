import { auth } from "../../Helpers/FirebaseConfig";
import ProfilePic from "../Avatar/ProfilePic";

const UserHeader = ({ currentMessage, gig = {} }) => {
  let recieverInfo;

  if (currentMessage) {
    recieverInfo =
      currentMessage.usersData[0].id === auth.currentUser.uid
        ? currentMessage.usersData[1]
        : currentMessage.usersData[0];
  }

  if (gig) {
    recieverInfo = {
      id: gig.sellerId,
      name: gig.sellerName,
      avatar: gig.sellerPicUrl,
      location: gig.location,
      rating: gig.rating,
    };
  }
  // console.log(recieverInfo);
  return (
    <div className="flex justify-between w-full">
      <div className="flex">
        <div className="overflow-hidden mr-4">
          <ProfilePic url={recieverInfo.avatar} />
        </div>
        <div>
          <a href={"/user?id=" + recieverInfo.sellerId} className=" font-bold ">
            {recieverInfo.name}
          </a>
          <div className=" font-light ">{recieverInfo.location || "location" }</div>
        </div>
      </div>
      {recieverInfo.rating ? <div className="flex">
        {[...Array(recieverInfo.rating)].map((e, i) => (
          <i className="fa fa-star text-yellow-400"></i>
        ))}
        {[...Array(5 - recieverInfo.rating)].map((e, i) => (
          <i className="fa fa-star text-gray-400"></i>
        ))}
      </div> : null}
    </div>
  );
};

export default UserHeader;
