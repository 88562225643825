import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { auth, db } from "../FirebaseConfig";
import { uploadFile } from "./files.controller";

const submitIdForVerification = async ({ file }) => {
  try {
    const path = `verification/${auth.currentUser.uid}/${
      file.name + Date.now().toString()
    }`;
    const snapshot = await uploadFile(file, path);
    const url = await getDownloadURL(snapshot.ref);

    const userRef = doc(db, "pendingVerification", auth.currentUser.uid);
    const user = await setDoc(
      userRef,
      {
        verification: url,
        name: auth.currentUser.displayName,
        email: auth.currentUser.email,
        photoURL: auth.currentUser.photoURL,
      },
      { merge: true }
    );
    return user;
  } catch (e) {
    console.log(e);
  }
};

const getVerifiedStatus = async (userId) => {
  try {
    const user = await getDoc(doc(db, "verifiedUsers", userId));
    console.log(user.data());
    if (user.exists()) {
      // if user verified by admin, return data
      return user.data().verificationStatus;
    } else {
      // if user not verified by admin, but have submitted id for verification, return true
      const fUser = await getDoc(doc(db, "pendingVerification", userId));
      if (fUser.exists()) {
        return fUser.data().verification;
      } else {
        // if user not verified by admin, and not submitted id for verification, return false
        return false;
      }
    }
  } catch (e) {
    console.log(e);
  }
};

const getInfo = async (userId) => {
  try {
    const user = await getDoc(doc(db, "users", userId));
    if (user.exists()) {
      return user.data();
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
  }
};

const deleteAccount = async () => {
  try {
    const user = auth.currentUser;
    const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      await deleteDoc(userRef);
      await user.delete();
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};

const submitPostForVerification = async (post) => {
  try {
    const user = await addDoc(collection(db, "pendingVerificationPosts"), post);
    return user;
  } catch (e) {
    console.log(e);
  }
};

const getPendingVerificationPosts = async () => {
  try {
    const posts = await getDocs(collection(db, "pendingVerificationPosts"));
    return posts;
  } catch (e) {
    console.log(e);
  }
};

const getUserByEmailAddress = async (email) => {
  try {
    const q = query(collection(db, "users"), where("email", "==", email));
    const user = await getDocs(q);
    return user;
  } catch (e) {
    console.log(e);
  }
};

const blockUser = async (userId) => {
  try {
    console.log(userId);
    const userRef = doc(db, "users", userId);
    console.log(userRef);
    const user = await setDoc(
      userRef,
      {
        blocked: true,
      },
      { merge: true }
    );
    console.log(user);
    return user;
  } catch (e) {
    console.log(e);
  }
};

const unblockUser = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const user = await setDoc(
      userRef,
      {
        blocked: false,
      },
      { merge: true }
    );
    console.log(user);
    return user;
  } catch (e) {
    console.log(e);
  }
};

const fetchBlockedUsers = async () => {
  try {
    const q = query(collection(db, "users"), where("blocked", "==", true));
    const users = await getDocs(q);
    return users;
  } catch (e) {
    console.log(e);
  }
};


export {
  submitIdForVerification,
  getVerifiedStatus,
  getInfo,
  deleteAccount,
  getUserByEmailAddress,
  submitPostForVerification,
  getPendingVerificationPosts,
  blockUser,
  fetchBlockedUsers,
  unblockUser,
};
