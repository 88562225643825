import React from "react";
import { useEffect } from "react";
import Comments from "../../Components/Comments/Comments";
import PostComponent from "../../Components/Posts/PostComponent";
import { dummyPost } from "../../dummy";
import {
  createComment,
  getPostById,
  getPostComments,
} from "../../Helpers/Controllers/post.controller";
import { useQuery } from "../../Helpers/customHooks";
import { auth } from "../../Helpers/FirebaseConfig";

function CommentsPage() {
  const [post, setPost] = React.useState(dummyPost);
  const [comments, setComments] = React.useState([]);
  const query = useQuery();
  const postId = query.get("id");

  useEffect(() => {
    async function fetchData() {
      setPost(await getPostById(postId));
      setComments(await getPostComments(postId));
      console.log(post, comments);
    }
    fetchData();
  }, []);

  const addComment = async (e) => {
    e.preventDefault();
    const comment = document.getElementById("pst_comment").value;
    // setComments([...comments, comment]);
    await createComment({
      postId,
      comment,
      commenterId: auth.currentUser.uid,
      commenterName: auth.currentUser.displayName,
      commenterAvatar: auth.currentUser.photoURL,
    });

    setComments(await getPostComments(postId));
    document.getElementById("pst_comment").value = "";
  };
  return (
    <div className="pt-20 px-4">
      {<PostComponent {...post} />}
      <div className="my-10">
        {comments ? (
          comments.map((comment) => {
            comment = comment.data();
            return <Comments {...comment} />;
          })
        ) : (
          <p>No comments yet</p>
        )}
      </div>
      <form onSubmit={(e) => addComment(e)} className="flex pb-4">
        <input
          id="pst_comment"
          type="text"
          className="w-full border-2 border-gray-300 p-2 rounded-lg"
          placeholder="Add a comment"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Post
        </button>
      </form>
    </div>
  );
}

export default CommentsPage;
