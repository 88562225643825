import React from "react";
import Slider from "react-slick";
import { d_Post_carouselImages, d_Post_settings } from "../../Settings/Texts";
import { dummyGig } from "../../dummy";
import { documentId } from "firebase/firestore";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "flowbite-react";
import UserHeader from "../../Components/Header/UserInfoHeader";
import Review from "../../Components/Utility/Review";
import { useQuery } from "../../Helpers/customHooks";
import { useEffect } from "react";
import { fetchGig } from "../../Helpers/Controllers/gig.controller";
import { createMessage } from "../../Helpers/Controllers/message.controller";
import { auth } from "../../Helpers/FirebaseConfig";

function GigPage() {
  const query = useQuery();
  const gigId = query.get("id");
  const [gig, setGig] = useState(null);

  useEffect(() => {
    async function fetchGigData() {
      setGig(await fetchGig(gigId));
    }
    fetchGigData();
  }, []);

  return (
    gig && (
      <div className="w-full flex flex-col lg:flex-row min-h-screen pt-20 ">
        <div className="flex flex-col mx-5 md:mx-16 lg:min-w-[50%]">
          <UserHeader gig={gig} />

          <h1 className=" text-3xl font-bold uppercase mt-2 mb-0">
            {gig.title}
          </h1>
          <p className="text-primary text-sm mt-0 mb-2">
            {gig.category} {">"} {gig.subCategory}
          </p>
          <div className="h-[60vh] w-full ">
            <Carousel slide={false}>
              {gig.images.map((image) => (
                <img src={image} alt="Hero" className="object-cover" />
              ))}
            </Carousel>
          </div>
          <div className="mt-4 ">
            <p className=" text-gray-700 font-bold text-xl">About this Gig</p>
            <p className=" text-gray-800 whitespace-pre-wrap">
              {gig.description}
            </p>
          </div>
          <ReviewSection reviews={gig.reviews} />
        </div>
        <PricingElement prices={gig.prices} gig={gig} />
      </div>
    )
  );
}

const ReviewSection = ({ reviews }) => (
  <div className="mt-4 ">
    <div className="flex justify-between">
      <h1 className=" text-3xl font-bold uppercase">Reviews</h1>
      <a href="askkd" className="text-primary">
        see all
      </a>
    </div>
    <div className="flex my-10 justify-center">
      {reviews ? (
        reviews.map((review, i) => <Review key={i} review={review} />)
      ) : (
        <p className="text-gray-700">No reviews yet</p>
      )}
    </div>
  </div>
);

const Header = ({ gig }) => {
  return (
    <div className="flex justify-between w-full mb-4">
      <div className="flex">
        <div className="w-14 h-14 rounded-[50%] overflow-hidden mr-4">
          <img
            src={
              "https://cdn.pixabay.com/photo/2016/12/21/00/36/woman-1921883_960_720.jpg"
            }
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div>
          <div className=" font-bold ">{gig.sellerName}</div>
          <div className=" font-light ">{gig.location}</div>
        </div>
      </div>
      <div className="flex">
        {[...Array(gig.rating)].map((e, i) => (
          <i className="fa fa-star text-yellow-400"></i>
        ))}
        {[...Array(5 - gig.rating)].map((e, i) => (
          <i className="fa fa-star text-gray-400"></i>
        ))}
      </div>
    </div>
  );
};

const PricingElement = ({ prices, gig }) => {
  const [currentPriceIndex, setCurrentPriceIndex] = useState(0);
  const priceNames = ["Basic", "Standard", "Premium"];
  const query = useQuery();
  const gigId = query.get("id");
  const navigate = useNavigate();
  const handleClick = async (type) => {
    if (type === "message") {
      const ret = await createMessage({
        senderId: auth.currentUser.uid,
        senderName: auth.currentUser.displayName,
        senderAvatar: auth.currentUser.photoURL,
        recieverId: gig.sellerId,
        recieverName: gig.sellerName,
        recieverAvatar: gig.sellerPicUrl,
        message: "Hello, I am interested in your gig",
        files: [],
        // gigId: gigId,
      });
      navigate("/messages");
    } else {
      navigate("/checkout");
    }
  };
  return (
    <div className="flex flex-col justify-between border-2 lg:mx-10 mx-5 lg:w-[50%]  h-[70vh]">
      <div className="h-full ">
        {/* <p className=" font-bold text-center py-2">
          Pricing
        </p> */}
        <div className="grid grid-cols-3 border">
          {priceNames.map((priceName, index) => (
            <div
              key={index}
              className={`${
                currentPriceIndex === index
                  ? "bg-primary text-white font-bold"
                  : "bg-none text-black"
              } text-center py-2 duration-300 cursor-pointer`}
              onClick={() => setCurrentPriceIndex(index)}
            >
              {priceName}
            </div>
          ))}
        </div>
        <div className=" overflow-y-hidden  ">
          <p className="text-lg text-gray-600 m-4 whitespace-pre-wrap ">
            {prices[currentPriceIndex].desc}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-3 border-t-2 mt-2 cursor-pointer">
        <div className={"flex items-center justify-center p-3 text-primary rounded " +( auth.currentUser ? " " : " col-span-3") }>
          <p className="font-bold text-lg">{prices[currentPriceIndex].price}</p>
        </div>
        {auth.currentUser && (
          <>
            <div className="flex items-center justify-center p-3 border-x-2 duration-200 hover:text-white hover:bg-primary">
              <i className="fa fa-person mr-1"></i>
              <p>Order Now</p>
            </div>
            <div
              onClick={() => handleClick("message")}
              className="flex items-center text-white bg-primary p-3 justify-center"
            >
              <i className="fa fa-comment mr-1"></i>
              <p>Message</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GigPage;
