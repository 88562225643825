const convertOrderObjectToStringSeparatedByDi = (orderObject) => {
  const orderString = Object.keys(orderObject)
    .map((key) => `${key} ${orderObject[key]}`)
    .join("<8=8=8=8> ");

  return orderString;
};

const convertStringSeparatedByDiToObject = (orderString) => {
//   try {
    const orderObject = {};
    orderString.split("<8=8=8=8> ").map((order) => {
      const [key, value] = order.split(" ");
      orderObject[key] = value;
    });
    return orderObject;
  
};

const getDateFromTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  // console.log(date.toISOString());
  // return date.toISOString()
  return `${date.toLocaleDateString()} : ${date.toLocaleTimeString()}`;
};

export {
  convertOrderObjectToStringSeparatedByDi as convertOrderObjectToStringSeparatedByComma,
  convertStringSeparatedByDiToObject as convertStringSeparatedByCommaToObject,
  getDateFromTimestamp
};
