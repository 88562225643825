import { getStorage, ref, uploadBytes } from "firebase/storage";

const storage = getStorage();

const uploadFile = async (file, path) => {
  const storageRef = ref(storage, path);
  const snapshot = await uploadBytes(storageRef, file);
  console.log("Uploaded a blob or file! ", snapshot);
  return snapshot;
};

export { uploadFile };
