import ArrowButton from "../Components/Buttons/ArrowButton";

const Hero_carouselImages = [
  "https://cdn.pixabay.com/photo/2019/08/14/04/40/himalayas-4404708_960_720.jpg",
  "https://cdn.pixabay.com/photo/2013/06/04/07/06/shosenkyo-drunk-116158_960_720.jpg",
  "https://cdn.pixabay.com/photo/2017/02/28/08/52/lagoon-2105231_960_720.jpg",
  "https://cdn.pixabay.com/photo/2017/05/09/03/46/alberta-2297204_960_720.jpg",
  "https://cdn.pixabay.com/photo/2016/08/11/23/48/mountains-1587287_960_720.jpg",
];

const Hero_settings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
};

const d_Post_carouselImages = [
  "https://cdn.pixabay.com/photo/2019/08/14/04/40/himalayas-4404708_960_720.jpg",
  "https://cdn.pixabay.com/photo/2014/01/02/10/14/beach-hut-237489_960_720.jpg",
  "https://cdn.pixabay.com/photo/2017/02/28/08/52/lagoon-2105231_960_720.jpg",
];

const d_Post_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  //   customPaging: function(i) {
  //     return (
  //       <a>
  //         <img src={`${baseUrl}/abstract0${i + 1}.jpg`} alt="" />
  //       </a>
  //     );
  //   },
  //   nextArrow: <ArrowButton right={true}/>,
  //   prevArrow: <ArrowButton right={false}/>
};

const categoriesWithImages = [
  {
    category: "Guide",
    image:
      "https://cdn.pixabay.com/photo/2013/03/03/15/49/mount-everest-89590_960_720.jpg",
  },
  {
    category: "Agency",
    image:
      "https://www.nutanix.com/content/dam/nutanix-newsroom/cloud-based-travel-agency-technology/img-digital-travel-agent-thumb.jpg",
  },
  // {
  //   category: "Hotel",
  //   image:
  //     "https://cdn.pixabay.com/photo/2016/10/18/09/02/hotel-1749602__340.jpg",
  // },
  {
    category: "Stay",
    image:
      "https://cdn.pixabay.com/photo/2016/11/18/17/46/house-1836070__340.jpg",
  },
  {
    category: "Food",
    image:
      "https://cdn.pixabay.com/photo/2018/05/01/18/21/eclair-3366430_960_720.jpg",
  },
  {
    category: "Vehicle",
    image:
      "https://cdn.pixabay.com/photo/2020/06/25/17/58/mercedes-glc-5340358_960_720.jpg",
  },
  {
    category: "Adventure",
    image:
      "https://cdn.pixabay.com/photo/2013/02/15/02/22/skydivers-81778_960_720.jpg",
  },
  {
    category: "Visa",
    image:
      "https://cdn.pixabay.com/photo/2017/08/14/22/06/passport-2642172__340.jpg",
  },
  {
    category: "Entertainment",
    image:
      "https://cdn.pixabay.com/photo/2016/08/05/21/47/lightshow-1573327_960_720.jpg",
  },
  // {
  //   category: "Events",
  //   image:
  //     "https://cdn.pixabay.com/photo/2017/07/21/23/57/concert-2527495__340.jpg",
  // },
  {
    category: "Ticketing",
    image:
      "https://cdn.pixabay.com/photo/2017/08/08/00/37/still-2609682_960_720.jpg",
  },
  {
    category: "Others",
    image:
      "https://cdn.pixabay.com/photo/2016/10/18/09/02/hotel-1749602__340.jpg",
  },
];

export {
  Hero_carouselImages,
  Hero_settings,
  d_Post_carouselImages,
  d_Post_settings,
  categoriesWithImages,
};
