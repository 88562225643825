import React from 'react'
import { useDispatch } from 'react-redux'
import { setShowPopup } from '../../app/slices/stateSlice';
import { auth } from '../../Helpers/FirebaseConfig';

function LinkToCopy() {
    const dispatch = useDispatch();
    const url = `https://www.traveltuk.com/refer?affiliateId=${auth.currentUser?.uid}`;
    const copyTextToClipboard = () => {
        const text = document.getElementById('link-to-copy').value
        navigator.clipboard.writeText(text);
        dispatch(setShowPopup(false))
    }
  return (
    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
        <h1>Please share this link for referral</h1>
        <textarea rows={3} value={url} className='p-2 border bg-gray-600 text-white font-bold rounded w-full' id='link-to-copy' />
        <div className="flex">
            <button onClick={() => copyTextToClipboard()} className='bg-primary p-2 rounded text-white font-bold w-full mr-2'>Copy</button>
            <button onClick={() => dispatch(setShowPopup(false))} className='bg-red-700 p-2 rounded text-white font-bold w-full'>Cancel</button>
        </div>
    </div>
  )
}

export default LinkToCopy