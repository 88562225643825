import {loadStripe } from "@stripe/stripe-js";

let stripePromise;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      // "pk_test_51LRBTRSBkkgPPNpux27aS5fwYJfKioUXRavdY1XaRZB8TOMwC5QlMu0r32SMpbFgnLHmQszSytD9azXmrYZWGJsI002uvvzJMj"
      "pk_live_51LRBTRSBkkgPPNpufVXtZdRuPEp0akq6ZcamP5Sfezt89hiuJnPaoHj5x1sTU1m7OOEox7tbksh5cvmKJh0jhq4700F8bK7BoS"
    );
  }
  return stripePromise;
};

export default initializeStripe;
