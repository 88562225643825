export default function FormAction({
  handleSubmit,
  type = "Button",
  action = "submit",
  text,
  loading = false,
}) {
  return (
    <>
      {type === "Button" ? (
        <button
          type={action}
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary/80 duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
        //   onSubmit={handleSubmit}
        >
          {!loading ? text : "Loading..."}
        </button>
      ) : (
        <></>
      )}
    </>
  );
}
