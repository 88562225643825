import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { db } from "../FirebaseConfig";
import { uploadFile } from "./files.controller";

const createPost = async ({
  title,
  category = "",
  subCategory = "",
  location,
  sellerId,
  sellerName,
  sellerImage,
  images,
}) => {
  // create post in firebase 9 database
  try {
    const imageLinks = [];
    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const path = `posts/${title}/${file.name + Date.now().toString()}`;
      const snapshot = await uploadFile(file, path);
      const url = await getDownloadURL(snapshot.ref);

      imageLinks.push(url);
      // gig.images[i] = snapshot.ref.fullPath;
    }
    

    const postRef = addDoc(collection(db, "posts"), {
      title,
      category,
      subCategory,
      location,
      sellerId,
      sellerName,
      sellerImage,
      images: imageLinks,
      createdAt: Timestamp.now()
    });
    return postRef;
  } catch (e) {
    console.log(e);
  }
};

const getPostsBySeller = async (sellerId) => {
  // fetch posts from firebase 9 database
  try {
    const postRef = collection(db, "posts");
    const q = query(postRef, where("sellerId", "==", sellerId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e.message, sellerId);
  }
};

const getPostComments = async (postId) => {
  // fetch comments from firebase 9 database
  try {
    const commentRef = collection(db, `posts/${postId}/comments`);
    // const q = query(commentRef, where("postId", "==", postId));
    const querySnapshot = await getDocs(commentRef);
    console.log(querySnapshot.docs[0].data());
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const likePost = async ({postId, userId}) => {
  // like post in firebase 9 database
  try {
    const postRef = doc(db, "posts", postId);
    const postDoc = await getDoc(postRef);
    if (postDoc.exists()) {
      const likes = postDoc.data().likes || [];
      if (likes.includes(userId)) {
        likes.splice(likes.indexOf(userId), 1);
      } else {
        likes.push(userId);
      }
      await setDoc(postRef, { likes }, { merge: true });
      return likes
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.log(e);
  }
};

const createComment = async ({
  postId,
  comment,
  commenterId,
  commenterName,
  commenterAvatar,
  
}) => {
  // create comment in firebase 9 database
  try {
    // const commentRef = doc(db, "comments", commenterId);
    // add comments in post document
    const commentRef = addDoc(collection(db, `posts/${postId}/comments`), {
        postId,
        comment,
        commenterId,
        commenterName,
        commenterAvatar,
        createdAt : Timestamp.now(),
    });
    return commentRef;
    // await setDoc(commentRef, {
    //   postId,
    //   comment,
    //   commenterId,
    //   commenterName,
    //   commenterAvatar,
    //   createdAt
    // });
  } catch (e) {
    console.log(e);
  }
};

const getPostById = async (postId) => {
  // fetch post from firebase 9 database
  try {
    const postRef = doc(db, "posts", postId);
    const post = await getDoc(postRef);
    return post.data();
  } catch (e) {
    console.log(e);
  }
};

const getAllPosts = async () => {
  // fetch posts from firebase 9 database
  try {
    // const postRef = collection(db, "posts");
    const q = query(collection(db, "posts"), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    // console.log(querySnapshot.docs[0].data());
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const deletePost = async (postId) => {
  // delete post from firebase 9 database
  try {
    const postRef = doc(db, "posts", postId);
    await deleteDoc(postRef);
  } catch (e) {
    console.log(e);
  }
};

export {
  createPost,
  getPostsBySeller,
  getPostComments,
  createComment,
  getPostById,
  getAllPosts,
  likePost,
  deletePost
};
