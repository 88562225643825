import { Link } from "react-router-dom";
import { googleSignIn } from "../../Helpers/Controllers/auth.controller";



export default function AuthHeader({
  heading,
  paragraph,
  linkName,
  linkAction,
}) {
  const signInButtonClicked = (e) => {
    // e.preventDefault();
    console.log("sign in button clicked");
    if (e.target.id === "google") {
      googleSignIn();
    }
  };
  return (
    <div className="mb-0">
      <div className="flex justify-center">
        <img
          alt=""
          className="h-14 w-14"
          src="https://ik.imagekit.io/pibjyepn7p9/Lilac_Navy_Simple_Line_Business_Logo_CGktk8RHK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649962071315"
        />
      </div>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {heading}
      </h2>
      <p className="text-center text-sm text-gray-600 mt-1">
        {paragraph}{" "}
        <span
          onClick={linkAction}
          className="font-medium text-purple-600 hover:text-purple-500 cursor-pointer"
        >
          {linkName}
        </span>
      </p>
      <div className="flex flex-row mt-3 items-center border-b-2 pb-2 justify-center lg:justify-between">
        <p className=" mb-0 text-gray-600 mr-4">Sign in with</p>
        <div className="flex flex-row items-center">
          <button
            id="google"
            onClick={() => googleSignIn()}
            className=" p-3 flex items-center text-gray-600 hover:text-white bg-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg active:shadow-lg transition duration-150 ease-in-out mx-1"
          >
            <i className="fa-brands fa-google text-red-600 w-4 mr-2"></i>
            <p>Sign in with Google</p>
          </button>

          {/* <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block p-3 bg-primary text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out mx-1"
          >
            <i className="fab fa-facebook-f w-4 h-4"></i>
          </button>

          <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block p-3 bg-primary text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out mx-1"
          >
            <i className="fa-brands fa-whatsapp w-4 h-4"></i>
          </button>
          <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block p-3 bg-primary text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out mx-1"
          >
            <i className="fa-brands fa-twitter w-4 h-4"></i>
          </button>
          <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block p-3 bg-primary text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out mx-1"
          >
            <i className="fa-brands fa-linkedin w-4 h-4"></i>
          </button> */}
        </div>
      </div>
    </div>
  );
}
