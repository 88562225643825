import { loadStripe } from "@stripe/stripe-js";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { createOrder } from "../Helpers/Controllers/orders.controller";
import { auth, db, functions } from "../Helpers/FirebaseConfig";
import initializeStripe from "./initializeStripe";

// export const createCheckoutSession = async (lineItems) => {

//   const docRef = collection(
//     db,
//     "users",
//     auth.currentUser.uid,
//     "checkout_sessions"
//   );
//   const checkoutSession = await addDoc(docRef, {
//     mode: "payment",
//     price: "price_1LutIfSBkkgPPNpubAPsyahk",
//     line_items: [
//       {
//         price_data: {
//           currency: "inr",
//           product_data: {
//             name: lineItems.gigTitle,
//           },
//           unit_amount: lineItems.price * 100, // ! unit must in INR
//         },
//         quantity: 1,
//       },
//     ],
//     success_url: window.location.origin+"/orders?session_id={CHECKOUT_SESSION_ID}&orderId="+lineItems.id,
//     cancel_url: window.location.origin,
//   });
//   // Wait for the desired state to occur before proceeding
//   const onChange = onSnapshot(checkoutSession, async (doc) => {
//     const { sessionId, error } = doc.data();

//     if (sessionId) {
//       const stripe = await initializeStripe();
//       stripe.redirectToCheckout({ sessionId });

//       // Unsubscribe from onSnapshot listener
//       onChange();
//       return sessionId;
//     }

//     if (error) {
//       // Show an error to your customer and
//       // inspect your Cloud Function logs in the Firebase console.
//       alert(`An error occured: ${error.message}`);
//       onChange();
//       return false;
//       // Unsubscribe from onSnapshot listener
//     }
//   });

//     // return checkoutSession;
// };

export const createCheckoutSession = async (lineItems) => {
  try {
    // firebase 9 functions call
    const createCheckoutSessioninBackend = httpsCallable(
      functions,
      "createStripeCheckOut"
    );
    const res = await createCheckoutSessioninBackend({
      // currency: "inr",
      // name: lineItems.gigTitle,
      // amount: lineItems.price * 100,
      success_url: window.location.origin + "/orders",
      cancel_url: window.location.origin,
      ...lineItems,
    });
    const stripe = await initializeStripe();
    console.log("res ==> ", res);
    const sc = await stripe.redirectToCheckout({ sessionId: res.data.id });
    // now create order in firestore


    return sc;
    // console.log("you son of a bitch", sc)
    // const orderRef = collection(db, "orders");
    // const newOrder = await addDoc(orderRef, {...lineItems, success_url: "localhost:3000/messages", cancel_url: "localhost:3000/messages"});
    // console.log(newOrder);
    // return newOrder;
  } catch (error) {
    alert(`An error occured: ${error.message}`);
  }
};
