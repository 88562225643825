import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { likePost } from "../../Helpers/Controllers/post.controller";
import { auth } from "../../Helpers/FirebaseConfig";

function PostComponent({
  id,
  title,
  price,
  sellerName,
  images = [],
  datePosted,
  sellerImage,
  profileUrl,
  sellerId,
  category,
  likes,
  createdAt,
  commentsCount,
  location,
  seller,
  rating,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };
  const [liked, setLiked] = useState(
    likes?.includes(auth.currentUser?.uid) || false
  );
  const [likesCount, setLikesCount] = useState(likes?.length || 0);
  const navigate = useNavigate();
  const navigatetoUrl = (url, params) => {
    console.log(url, params);
    navigate({ pathname: url, search: "?id=" + params });
  };

  const updateLike = async () => {
    
    if (auth.currentUser) {
      setLiked(!liked);
      if (liked) {
        setLikesCount(likesCount - 1);
      } else {
        setLikesCount(likesCount + 1);
      }
      await likePost({ postId: id, userId: auth.currentUser?.uid });
    } else {
      alert("Please login to like the post");
    }
  };

  const createAffiliateLinkAndShowInPopup = () => {
    const url = `https://www.traveltuk.com/post?id=${id}&affiliateId=${auth.currentUser?.uid}`;
    // window.open(url, "_blank", "width=500,height=500");
    alert("Please share this unique referral url and earn on your account \n ⬇ \n" + url);
  };

  return (
    <div className=" bg-white border-[2px] max-w-2xl hover:shadow-2xl duration-150 p-2 rounded-md m-2">
      <div className="flex items-center">
        <div className="img mr-2">
          <img
            src={sellerImage}
            className="w-10 h-10 rounded-full object-cover "
            alt=""
          />
        </div>
        <div className="info w-full flex flex-col justify-start">
          <div className="flex justify-between items-center">
            <a href={"/user?id=" + sellerId} className=" font-bold text-lg m-0">
              {sellerName}
            </a>
            {/* <p className="flex">
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
            </p> */}
            <i className="fa fa-ellipsis-h text-gray-400"></i>
          </div>
          <p className="text-gray-600  font-thin text-sm">
            {createdAt
              ? Date(createdAt?.seconds).toString().split("GMT")[0]
              : "1 hour ago"}
            {" • "}
            {location}
          </p>
        </div>
      </div>
      <div className=" text-md my-2">{title}</div>
      <div className="">
        <Slider {...settings}>
          {images.map((image) => (
            <img src={image} alt="" />
          ))}
        </Slider>
      </div>
      <div className="flex text-gray-500">
        <div className="flex items-center mr-2">
          <i className="fa fa-thumbs-up text-lg"></i>
          <p>{likesCount || 0}</p>
        </div>
      </div>
      <div className="grid grid-cols-3 border-t-2 mt-2 p-2 cursor-pointer ">
        <div
          onClick={() => updateLike()}
          className={`flex  items-center justify-center ${
            liked ? "text-primary" : " text-gray-500"
          }`}
        >
          <i className="fa fa-thumbs-up mr-1 text-lg hover:text-red-300"></i>
          <p>Like</p>
        </div>
        <div
          className="flex hover:text-primary/80 items-center justify-center border-x-2"
          onClick={() => {
            navigatetoUrl("/post", id);
          }}
        >
          <i className="fa fa-comment mr-1"></i>
          <p>Comment</p>
        </div>
        <div onClick={() => createAffiliateLinkAndShowInPopup()} className="flex hover:text-primary/80 items-center  justify-center">
          <i className="fa fa-share mr-1"></i>
          <p>share</p>
        </div>
      </div>
    </div>
  );
}

export default PostComponent;
