import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { db } from "../FirebaseConfig";
import { uploadFile } from "./files.controller";

const createMessage = async ({
  senderId,
  senderName,
  senderAvatar,
  recieverId,
  recieverName,
  recieverAvatar,
  message,
  files = [],
  // gigId,
}) => {
    
  // create message in firebase 9 database
  try {
    // get what message room is it
    const q = query(
      collection(db, "messages"),
      where("users", "in", [[senderId, recieverId], [recieverId, senderId]]),
      // where("users", "in", [recieverId])
    );
    // if first time message, create room
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length === 0) {
      console.log("no rooms found");
      const messageRef = addDoc(collection(db, "messages"), {
        users: [senderId, recieverId],
        usersData: [
          {
            id: senderId,
            name: senderName,
            avatar: senderAvatar,
          },
          {
            id: recieverId,
            name: recieverName,
            avatar: recieverAvatar,
          },
        ],
        lastMessage: {
          senderId,
          senderName,
          senderAvatar,
          recieverId,
          recieverName,
          recieverAvatar,
          message,
          // gigId,
          createdAt: Timestamp.now(),
        },
        // messages: [
        //   {
        //     senderId,
        //     senderName,
        //     senderAvatar,
        //     message,
        //     files,
        //     gigId,
        //     createdAt: Timestamp.now(),
        //   },
        // ],
      });
      return messageRef;
    } else {
      const imageLinks = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const path = `messagesFiles/${file.name + Date.now().toString()}`;
      const snapshot = await uploadFile(file, path);
      const url = await getDownloadURL(snapshot.ref);

      imageLinks.push(url);
      // gig.images[i] = snapshot.ref.fullPath;
    }
    const myF = imageLinks || [];
      // now add message to the room
      const messageRef = addDoc(
        collection(db, `messages/${querySnapshot.docs[0].id}/messages`),
        {
          senderId,
          senderName,
          senderAvatar,
          message,
          files : myF,
          // gigId,
          createdAt: Timestamp.now(),
        }
      );
      return messageRef;
    }
  } catch (e) {
    console.log(e);
  }
};

const getMessagesByUser = async ({ userId }) => {
  // fetch message rooms for user
  try {
    const q = query(
      collection(db, "messages"),
      where("users", "array-contains", userId)
    )
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

const getMessagesByRoom = async (roomId) => {
  // fetch messages from firebase 9 database
  try {
    const q = query(collection(db, `messages/${roomId}/messages`), orderBy("createdAt", "asc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (e) {
    console.log(e);
  }
};

export { createMessage, getMessagesByUser, getMessagesByRoom };
