import { async } from "@firebase/util";
import { Alert } from "flowbite-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import GigSearch from "../../Components/Forms/GigSearch";
import Gig from "../../Components/Gigs/Gig";
import SearchFilter from "../../Components/Utility/SearchFilter";
import { dummy } from "../../dummy";
import { fetchGigsBySearchTerm, getGigByCategory } from "../../Helpers/Controllers/gig.controller";
import { useQuery } from "../../Helpers/customHooks";
import { categoriesWithImages } from "../../Settings/Texts";

function SearchPage() {
  let query = useQuery();
  const [currentSearchTerm, setCurrentSearchTerm] = useState(
    query.get("location")
  );
  const [currentCategory, setCurrentCategory] = useState(query.get("category"));
  const [gigs, setGigs] = useState([]);

  useEffect(() => {
    async function fetchGigs() {
      setCurrentSearchTerm(query.get("location"));
      setCurrentCategory(query.get("category"));
      if (currentSearchTerm !== null) {
        setGigs(await fetchGigsBySearchTerm({ location: currentSearchTerm }));
      } else {
        setGigs(await getGigByCategory({ category: currentCategory }));
      }
      // console.log(gigs);
    }
    fetchGigs();
  }, [currentSearchTerm]);

  return (
    <div className=" ">
      <div className="header pt-20 py-10 px-5 md:px-20 bg-slate-500" style={{backgroundImage: `url("${categoriesWithImages.find(a => a.category === currentCategory).image}")`, backgroundSize: "cover"}}>
        <GigSearch />
        <SearchFilter />
      </div>
      <p className="mx-20 my-6 text-3xl font-bold text-gray-600">
        Search results for{" "}
        <span className="text-black">{currentSearchTerm}</span>
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-20">
        {gigs &&
          gigs.map((item, i) => {
            const id = item.id;
            item = item.data();
            item.id = id;
            return <Gig key={id} {...item} />;
          })}
      </div>
    </div>
  );
}

export default SearchPage;
