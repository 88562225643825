import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  categoriesWithImages,
  Hero_carouselImages,
  Hero_settings,
} from "../../Settings/Texts";
import { useNavigate } from "react-router-dom";
import { categoriesAndSubcategories } from "../../Helpers/constants";
import GigSearch from "../Forms/GigSearch";

function Hero() {
  
  const navigate = useNavigate();
  const [locationText, setLocationText] = useState("");
  // const gotoSearchPage = () => {
  //   const category = document.getElementById("categories-hero").value;
  //   let query = "?";
  //   query += `location=${locationText}`;
  //   if (category !== "All Categories") {
  //     query += `&category=${category}`;
  //   }

  //   navigate({
  //     pathname: "/search",

  //     search: query,
  //   });
  // };

  return (
    <div className="h-[60vh] w-screen bg-secondary relative overflow-hidden">
      <div className=" w-full min-h-full" style={{ zIndex: "-1" }}>
        <Slider {...Hero_settings}>
          {Hero_carouselImages.map((image, i) => (
            <img
              src={image}
              key={i}
              alt="Hero"
              className="w-full h-[60vh] object-cover"
            />
          ))}
        </Slider>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-black/0"></div>
      <div className="absolute top-0 left-0 w-full pt-20 md:pt-50 lg:pt-90 h-full flex flex-col justify-start items-center ">
        <h1 className="text-3xl md:text-4xl lg:text-6xl mx-2 font-bold text-white/90 mb-2">
          Find Your Next Adventure
        </h1>
        {/* <p className="text-2xl text-white">Find the best gigs in your area</p> */}
        <GigSearch />
        {/* <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-10 gap-4 mt-10">
          {categoriesWithImages.map((category, i) => (
            
            <div
              key={i}
              onClick={() => {
                setCategory(category);
                navigate({
                  pathname: "/search",
                  search: `?category=${category.category}`,
                });
              }}
              className="category-card flex flex-col justify-center items-center w-40 max-h-40 mx-2 rounded hover:shadow-xl duration-500 hover:-translate-y-6 cursor-pointer"
              style={{
                background: `linear-gradient(0deg, rgba(50, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url(${category.image})`,
                backgroundSize: "cover",
                height: "20rem",
              }}
            >
              <h1 className="text-3xl font-bold text-white/70 break-words  max-w-full">
                {category.category}
              </h1>
              <button className="hover:bg-black bg-black/40 transition-all duration-150 border-2 hover:border-black text-white hover:text-white px-4 py-2 rounded text-xl font-bold mt-4">
                Explore
              </button>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Hero;
