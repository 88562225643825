import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentMessage } from "../../app/slices/stateSlice";
import { getMessagesByUser } from "../../Helpers/Controllers/message.controller";
import { users } from "../../Helpers/dummyData";
import { auth } from "../../Helpers/FirebaseConfig";

// leftmost column of the message page
function MessageBox({ toggleMessageListOnONmobile }) {
  const dispatch = useDispatch();
  const currentMessage = useSelector((st) => st.state.currentMessage) || "";
  const [usersList, setUsersList] = useState([]);
  useEffect(() => {
    try {
      async function fetchData() {
        // usersList is the message room
        setUsersList(await getMessagesByUser({ userId: auth.currentUser.uid }));
        console.log(usersList);
        if (usersList.length > 0) {
          let tempM = usersList[0].data();

          const id = usersList[0].id;
          tempM.id = id;
          dispatch(setCurrentMessage(tempM));
        }
      }
      if (auth.currentUser) fetchData();
      // fetchData();
    } catch (error) {
      console.log(error);
    }
  }, [auth.currentUser, dispatch]);
  return (
    <div>
      <input
        type="text"
        placeholder="Search"
        className=" rounded w-full my-4"
      />
      {usersList?.length > 0 ? (
        usersList.map((messageRooms) => {
          const id = messageRooms.id;
          messageRooms = messageRooms.data();
          const thisUser =
          messageRooms.usersData[0].id === auth.currentUser?.uid
          ? messageRooms.usersData[1]
          : messageRooms.usersData[0];
          messageRooms.id = id;
          return (
            <div
              key={id}
              onClick={async () => {
                await dispatch(setCurrentMessage(messageRooms));
                toggleMessageListOnONmobile();
              }}
              className={`flex items-center p-2  cursor-pointer border-b-2 ${
                currentMessage.id === messageRooms.id
                  ? "bg-gray-400 hover:bg-gray-600"
                  : "hover:bg-gray-200"
              }`}
            >
              <img
                src={thisUser.avatar}
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <div className="flex flex-col ml-2">
                <p className="font-semibold">{thisUser.name}</p>
                <p className="text-gray-500 text-sm">Last message</p>
              </div>
            </div>
          );
        })
      ) : (
        <p className="grid place-items-start">No Active Messages</p>
      )}
    </div>
  );
}

export default MessageBox;
