import { Spinner } from "flowbite-react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPopupContent, setShowPopup } from "../../app/slices/stateSlice";
import Gig from "../../Components/Gigs/Gig";
import PostComponent from "../../Components/Posts/PostComponent";
import Review from "../../Components/Utility/Review";
import ReviewSection from "../../Components/Utility/Review";
import { dummyGig, dummyProfile } from "../../dummy";
import { sendSlToEm } from "../../Helpers/Controllers/auth.controller";
import {
  deleteGig,
  getGigsBySeller,
} from "../../Helpers/Controllers/gig.controller";
import {
  deletePost,
  getAllPosts,
  getPostsBySeller,
} from "../../Helpers/Controllers/post.controller";
import {
  getVerifiedStatus,
  submitIdForVerification,
} from "../../Helpers/Controllers/user.controller";
import { auth } from "../../Helpers/FirebaseConfig";
import { verifySellerPayout } from "../../Stripe/verifySellerPayout";



// ! todo - Refactor this mess

function ProfilePage() {
  let navigate = useNavigate();
  const [myGigs, setMyGigs] = useState([]);
  const [myPosts, setMyPosts] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState("unverified");
  const [myReviews, setMyReviews] = useState([]);
  const [user, loading, error] = useAuthState(auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && !loading) {
      navigate("/login");
    }
    try {
      async function fetchData() {
        const gigs = await getGigsBySeller(auth.currentUser?.uid);
        const posts = await getPostsBySeller(auth.currentUser?.uid);
        const verificationStatus = await getVerifiedStatus(
          auth.currentUser?.uid
        );
        // const reviews = await getMyReviews();
        setMyPosts(posts);
        setMyGigs(gigs);
        setVerificationStatus(verificationStatus);
        // console.log(gigs);
        // setMyPosts(posts);
        // setMyReviews(reviews);
      }
      fetchData();
    } catch (error) {
      console.log(error);
      // navigate("/login");
    }
  }, [auth.currentUser, loading]);

  const handleDelete = async (gigId) => {
    await deleteGig(gigId);
    const gigs = await getGigsBySeller(auth.currentUser?.uid);
    setMyGigs(gigs);
  };
  const handleDeletePost = async (postId) => {
    await deletePost(postId);
    const posts = await getPostsBySeller(auth.currentUser?.uid);
    setMyPosts(posts);
  };

  const [vloading, setvLoading] = useState(false);

  const submitForVerification = async (idFile) => {
    console.log(idFile);
    await submitIdForVerification({ file: idFile });
    setVerificationStatus("pending");
  };

  const handleEdit = (gigId) => {
    navigate(`/edit-gig/${gigId}`);
  };
  return (
    <div className="pt-20">
      <div className="picsandgigs grid gap-1 grid-cols-1 md:grid-cols-3">
        <div className="picsanddetails border rounded p-4 mx-4 text-center col-span-2 md:col-span-1">
          <div className="profilepic">
            <img
              src={auth.currentUser?.photoURL || ""}
              alt="profilepic"
              className="max-w-full rounded-lg object-cover"
            />
          </div>
          <div className="details flex flex-col h-full items-between">
            <div className="name flex justify-between p-2 border-b-2">
              <h1 className="text-3xl font-bold">
                {auth.currentUser?.displayName}
              </h1>
              <h1 className=" border border-green-500 text-green-500 text-md rounded-full p-1 font-light">
                online
              </h1>
            </div>
            <div className="location flex justify-between p-2">
              <p className="text-gray-800 font-bold">from</p>
              <p className="text-gray-500">{dummyProfile.location}</p>
            </div>
            <div className="dateofjoin flex justify-between p-2">
              <p className="text-gray-800 font-bold">Member Since</p>
              <p className="text-gray-500">
                {auth.currentUser?.metadata.creationTime.substring(5, 16)}
              </p>
            </div>
            <p>
              {console.log(auth.currentUser?.emailVerified)}
              {" Settings"}
            </p>
            {!auth.currentUser?.emailVerified && false && (
              <button
                onClick={async () => {
                  await sendSlToEm(auth.currentUser?.email);
                }}
                className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
              >
                verify email
              </button>
            )}
            <button
              onClick={() => {
                dispatch(setPopupContent("link-to-copy"));
                dispatch(setShowPopup(true))
              }}
              className="bg-amber-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Affiliate
            </button>
            <button
              onClick={() => {
                dispatch(setPopupContent("link-to-copy"));
                dispatch(setShowPopup(true))
              }}
              className="bg-amber-500 my-2 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Refer and earn
            </button>
            <button
              onClick={() => {
                dispatch(setPopupContent("link-to-copy"));
                dispatch(setShowPopup(true))
              }}
              className="bg-amber-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Promotion
            </button>
            <button
              onClick={async () => {
                if (!vloading) {
                  setvLoading(true);
                  const redUrl = await verifySellerPayout();
                  window.location.href = redUrl.data.url;
                  setvLoading(false);
                }
              }}
              className="bg-blue-500 mt-2 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              {vloading ? <Spinner /> : "Verify Payout"}
            </button>
            <button
              onClick={ () => {
                dispatch(setPopupContent("delete-acc"))
                dispatch(setShowPopup(true))
              }}
              className="bg-red-700 mt-2 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Delete Account
            </button>
          </div>
        </div>
        <div className="bioandgigs col-span-1 md:col-span-2">
          <div className="bio border rounded p-4 mx-4">
            <h1 className="text-2xl font-bold">Authencity</h1>
            {/* <p className="text-gray-500">{dummyProfile.bio}</p> */}
            {!verificationStatus ? (
              <div className="verify">
                account not verified yet, please verify your account by
                uploading your passport or driving license
                <input
                  type="file"
                  onChange={async (e) =>
                    submitForVerification(e.target.files[0])
                  }
                />
              </div>
            ) : (
              <div className="verified">
                verificationStatus : {verificationStatus.toString()}
              </div>
            )}
          </div>
          <div className="gigs border rounded p-4 m-4">
            <h1 className="text-2xl font-bold">Gigs</h1>
            <div className="giggrid grid grid-cols-1 md:grid-cols-2 gap-4">
              {myGigs ? (
                myGigs.map((gig) => {
                  const id = gig.id;
                  gig = gig.data();
                  gig.id = id;
                  return (
                    <div key={id} className="relative ">
                      <div className="updORdlt group absolute h-8 flex justify-end w-full z-10">
                        <div
                          onClick={() => handleDelete(gig.id)}
                          className=" bg-red-700  w-16 h-8 grid place-items-center rounded cursor-pointer"
                        >
                          <i className="fas fa-trash-alt text-white"></i>
                        </div>
                        {/* <div className=" bg-green-700 w-16 h-8 grid place-items-center rounded  cursor-pointer">
                          <i className="fas fa-edit text-white"></i>
                        </div> */}
                      </div>
                      <Gig key={id} {...gig} />
                    </div>
                  );
                })
              ) : (
                <div>loading</div>
              )}
              {auth.currentUser && (
                <div
                  onClick={() => navigate("/createGig")}
                  className="m-2 py-20 cursor-pointer border hover:shadow-lg duration-300 grid place-items-center bg-white hover:shadow-black/60 rounded"
                >
                  <div className="text-4xl text-gray-500 flex flex-col items-center">
                    <i className="fas fa-plus "></i>
                    <p>Add a new Gig</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="postsandreviews grid grid-cols-5 ">
        {/* <div className="reviews border rounded p-4 m-4 col-span-5 md:col-span-2">
          <h1 className="text-2xl font-bold">Reviews</h1>
          <div className="reviewsgrid grid grid-cols-1 gap-4">
            {dummyProfile.reviews.map((review, i) => (
              <Review key={i} review={review} />
            ))}
          </div>
        </div> */}
        <div className="posts border rounded p-4 m-4 col-span-5 md:col-span-3">
          <h1 className="text-2xl font-bold">Posts</h1>
          <div className="postscolumn">
            {myPosts ? (
              myPosts.map((post) => {
                const id = post.id;
                post = post.data();
                post.id = id;
                // console.log(post);
                return (
                  <div key={id} className="relative">
                    <div className="updORdlt group absolute h-8 flex justify-end w-full z-10">
                      <div
                        onClick={() => handleDeletePost(post.id)}
                        className=" bg-red-700  w-16 h-8 grid place-items-center rounded cursor-pointer"
                      >
                        <i className="fas fa-trash-alt text-white"></i>
                      </div>
                      {/* <div className=" bg-green-700 w-16 h-8 grid place-items-center rounded  cursor-pointer">
                        <i className="fas fa-edit text-white"></i>
                      </div> */}
                    </div>
                    <PostComponent key={post.id} {...post} />
                  </div>
                );
              })
            ) : (
              <div>No Post by this User</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
