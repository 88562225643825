import React from 'react'

function TermsAndCondition() {
  return (
    <div className='mx-5 py-20 md:mx-20 text-gray-700 font-light font-md whitespace-pre-wrap' >{`Terms & Conditions
    • Booking your Holiday
    1. 
        1. 
            a. Your contract in respect of your holiday is made with Ocean Blue Travel (Seychelles) (‘the Company‘), registered in Seychelles and all bookings are subject to these terms and conditions.
            b. All communications by the Company in relation to your holiday will be sent to the client’s email address.
            c. All special requests, such as dietary requirements, should be noted in the client’s or supplier email address.
            d. The Company will provide the service as set out and confirmed by email.
    • Price Policy
    1. 
        1. 
            a. The Company is under no obligation to furnish a breakdown of the costs involved in a holiday.
            b. The Company reserves the right to notify you of any increase in price last minute or before accepting your booking.
            c. The financial commitments offered above by the Company mean that the Company is not able to reduce the holiday prices should the value of the Seychelles Rupee strengthen.
    • Cancellation and Changes by the Company
    1. 
        1. 
            a. Occasionally it may be deemed necessary to make changes to your holiday and the Company reserves the right to do so at any time, and you will be notified of any changes at the earliest possible opportunity. If a major change to your package is necessary, providing it does not arise from circumstances beyond the Company’s control, you may choose (i) to accept the change of arrangements (ii) to purchase another accommodation from us or (iii) to cancel your holiday. Compensation may be payable in cases of major change as detailed below.
            b. No compensation will be payable for minor changes. Minor changes include minimal changes to departure and arrival times, changes to the type of aircraft used and accommodation changes to a comparable or superior standard.
            c. Compensation will not be paid for changes or cancellations caused by Acts of God (Force Majeure), client no shows, flight cancellations, delays in departure or return, war, threat of war, riot, civil strike, industrial dispute, terrorist activity, natural or man-made disaster, fire, technical problems to transport, closure or congestion of airports, strikes or other industrial action, adverse weather conditions or any other event beyond the Company’s control. It is essential that you take out adequate travel insurance.
            d. The Company reserve the right to cancel your holiday at any time before the date of departure, even after a confirmation notice has been sent. If your holiday is cancelled the Company will refund in full the money you have already paid. No compensation will be payable.
            e. Transportation is subject to the conditions of carriage of the carrier, some of which may limit or exclude liability.
            f. If you fail to pay the balance of the holiday price at least 10 days before arrival in Seychelles, the Company will treat the booking as cancelled and levy the cancellation charges set out below.
    • Payment Terms
    1. 
        1. 
            a. 50 % or 100% bank transfer in Euro for the total invoice sent to you (Charges are bared by the client).
            b. Credit card details (for the moment American express is not accepted)
            c. All payments should be settled during your stay in Seychelles by:
    • 
        ◦ 
            ▪ 
                • Card payments
                • Cash payments
United Stated Dollar is also accepted. All currencies shall be converted through our local bank rates.
    • Cancellation Policy
    1. 
        1. 15 days + cancellation before arrival, full refund  will be  issued for accommodation booked with the company
        2. 4- 14  days cancellation before arrival, 50% of your total accommodation invoice will be charged
        3. 0-3 days cancellation before arrival, 100% of your total accommodation invoice will be charged
        4. 24 hours cancellation or more free cancellation for activities and excursions booked with the company
        5. within 24 hours , 25% of total charge for the activity, excursion or transport charged to the client or supplier
        6. less than 12 hours, 100% charge for the activity, excursion or transport charged to the client or supplier
        7. 100% full refund due to bad weather. The company reserves the right to move the activity or excursion to another date
Note: This policy does not apply to Special Promotions. Ocean Blue Travel Seychelles will advise you during your booking confirmation. The company can be flexible during low season.
    • Our Responsibilities
    1. 
        1. 
            a. The Company does not own or manage the aircraft, accommodation, restaurants and other facilities used in conjunction with the bookings arranged. While the Company has exercised care in selecting providers of travel, accommodation, restaurants and other facilities, the Company have not had the opportunity to inspect and do not represent that such aircraft, accommodation, restaurants, and other facilities and services have been inspected.
            b. The Company is not responsible if you or any member of your party suffer death, illness or injury as a result of any failure to perform or improper performance of any part of our contract with you where such failure is attributable to (i) the acts and/or omissions of any member of the party, or (ii) those of a third party not connected with the provision of your holiday, or (iii) an event which neither the Company or the service provider could have foreseen or prevented even with due care.
            c. Should any client or member of your party suffer illness, injury or death through misadventure arising out of an activity, which does not form part of the holiday the Company has arranged for you the Company cannot accept liability. The Company will offer general assistance where appropriate.
    • Your Responsibilities
    1. 
        1. 
            a. You are responsible for arranging your own travel insurance. Details of all insurance policies held by each member of your party must be provided on the booking form. No booking forms can be accepted without sufficient proof that personal policies have been arranged. Please ensure that your insurance cover applies to the specific activities you are booking and which are confirmed on the Confirmation Invoice.
            b. Each member of the party must have a valid passport, visas and all necessary documentation for Seychelles. The Company accept no responsibility for any delay or expense should your documents not be in order.
            c. You are responsible for checking-in for flights at the correct time and for presenting yourself to take up all pre-booked components of your holiday. The Company cannot accept responsibility for clients missing flights as a result of late check-ins and no credit or refunds will be given if you fail to take up any component of your holiday. No credit of refunds will be given for lost, mislaid or destroyed travel documents.
            d. By booking a holiday with the Company you undertake to behave in an orderly manner and not to disrupt the enjoyment of others on holiday with you nor to do anything to bring the reputation of the Company into disrepute. If you breach this clause your holiday will be terminated with immediate effect and the Company will have no further contractual obligation to you. The Company will be entitled to recover from the offending party and/or the person who signed the booking form compensation for any damage caused.
            e. It is the responsibility of the person who signs the Booking Form to disclose any pre-existing medical conditions that members of their party may have.
            f. All equipment and personal effects shall be all times and in all circumstances at the owner’s risk. The Company cannot accept responsibility for any loss or damage or delay to your luggage or effects unless directly caused by the negligence of one of our representatives.
    • Smoking
The Company forbids smoking in any vehicle operated by the Company. Frequent stops can be arranged for those who desire to smoke. All accommodation will be reserved with non-smoking rooms, unless the client specifically requires a smoking room. Confirmation is depending on hotel availability.
    • Waiver
The Company will undertake to ensure the safety of the client throughout the activities booked, however aspects of each particular tour may not be without an element of danger. Clients should be prepared to sign a ‘Waiver of Responsibility’ form before they begin their tour. This form is an understanding that all clients need to exercise judgement and care at all times to ensure their own safety and that of their fellow tourists.
    • Disclaimer
The Company has made all reasonable efforts to check the accuracy of the information contained in our website. The Company cannot however accept any responsibility for any errors or omissions that may appear in this site.
    • Complaints
    1. 
        1. 
            a. If you have a problem during your holiday, please inform the relevant airline and hotel immediately so that they can endeavour to put things right. If you cannot resolve the problems, you must contact the Company office so that they are given an opportunity to help. The Company will not hold themselves responsible for the non-performance of an itinerary through causes beyond their control or when they are not notified of a problem at the point where remedial action can be taken. In the unlikely event that a complaint cannot be resolved at the time, you should write to the Company within 28 days of returning home, all other relevant information. If you fail to take any of these steps you will hinder the Company’s ability to put any problem right and/or investigate it fully and any right you have to receive compensation will be reduced or completely invalidated.
            b. Any dispute that may arise will be governed by Seychellois law and both parties shall submit to the jurisdiction of the Seychelles Supreme Court.
            c. All information given by the Company whether in writing or orally is to the best of the Company’s knowledge and believed correct at the time given and is given in good faith. The booking conditions shall take precedence over any other warranty or condition that may have been given.
    • Data Protection Act
It may be necessary for the Company to ask you for certain personal information. Examples of this would be dietary requirements, disability/medical or religious information etc. This information will be kept confidential by the Company and is available to you to inspect during the Company’s normal working hours. It will be passed to the suppliers, if it is necessary for them to know this information in order to fulfill the Company’s contract to you.
    • Copyright
All material contained on the Web site (including all software, images, text, HTML code, Java applets, Active X controls and other code) is protected by copyright laws. Except as otherwise expressly provided in these terms and conditions, you may not copy, distribute, transmit, display, perform, reproduce, publish, license, modify, rewrite, create derivative works from, transfer, or sell any material contained on the Web site without the prior consent of the copyright owner.
None of the material contained on oceanblueseychelles.com may be reverse-engineered, disassembled, decompiled, transcribed, stored in a retrieval system, translated into any language or computer language, retransmitted in any form or by any means (electronic, mechanical, photo reproduction, recordation or otherwise), resold or redistributed without the prior written consent of Ocean Blue Travel (Seychelles) Limited. Violation of this provision may result in severe civil and criminal penalties.

    `}</div>
  )
}

export default TermsAndCondition