import React from "react";
import { Modal, Checkbox, Label, TextInput, Button } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { setShowPopup } from "../../app/slices/stateSlice";
import CreateOffer from "../PopupComponents/CreateOffer";
import DeleteAccPopup from "../Profile/DeleteAccPopup";
import LinkToCopy from "../PopupComponents/LinkToCopy";

function CustPopup() {
  const show = useSelector((s) => s.state.showPopup);
  const popupContent = useSelector((s) => s.state.popupMessage);
  const dispatch = useDispatch();

  const contents = {
    "create-offer": <CreateOffer />,
    "update-gig": <CreateOffer />,
    "delete-acc": <DeleteAccPopup />,
    "link-to-copy": <LinkToCopy/>
  };
  return (
    <Modal
      show={show}
      size="md"
      popup={true}
      onClose={() => dispatch(setShowPopup(false))}
    >
      <Modal.Header />
      <Modal.Body>{contents[popupContent]}</Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default CustPopup;
