import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { setCurrentRefferalUid } from "../../app/slices/stateSlice";
import { useQuery } from "../../Helpers/customHooks";
import { auth } from "../../Helpers/FirebaseConfig";

function Refer() {
  const dispatch = useDispatch();
  const query = useQuery();
  useEffect(() => {
    //  save the ref data to redux
    if (
      query.get("affiliateId") &&
      query.get("affiliateId") !== auth.currentUser?.uid
    ) {
      // dispatch(setCurrentRefferalUid(query.get("affiliateId")));
      window.localStorage.setItem(
        "affiliateId",
        query.get("affiliateId")
      );
    }
    // redirect to homepage
    window.location.href = "/";
    // when account is created, use that data and save it on users collection
  }, []);
  return <div>Refer</div>;
}

export default Refer;
