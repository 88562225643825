import { Button, Card } from "flowbite-react";
import React from "react";
import { useEffect } from "react";
import OrdersComponent from "../../Components/Utility/OrderComponent";
import {
  fetchOrdersByBuyerId,
  fetchOrdersBySellerId,
} from "../../Helpers/Controllers/orders.controller";
import { auth } from "../../Helpers/FirebaseConfig";
import { getBalance } from "../../Stripe/getBalance";

function OrdersPage() {
  const sampleOrders = [
    {
      id: 1,
      gigTitle: "I will walk with you",
      description: "Lets travel around ktm",
      status: "pending",
      price: 100,
      startDate: "2021-09-01",
      deadline: "2021-09-10",
      buyerName: "John Doe",
      sellerName: "Jane Doe",
    },
    {
      id: 2,
      gigTitle: "I will create a website for you",
      description: "Lets travel around pokhara",
      status: "approved",
      price: 100,
      startDate: "2021-09-01",
      deadline: "2021-09-10",
      buyerName: "John Doe",
      sellerName: "Jane Doe",
    },
  ];

  const [orders, setOrders] = React.useState();
  const [MyOrders, setMyOrders] = React.useState();
  const [balanc, setBalance] = React.useState({});
  useEffect(() => {
    try {
      async function fetchOrders() {
        const res = await fetchOrdersBySellerId({
          sellerId: auth.currentUser?.uid,
        });
        const userAsBuyerOrder = await fetchOrdersByBuyerId({
          buyerId: auth.currentUser?.uid,
        });
        const balande = await getBalance();
        console.log(balande);

        setOrders(res);
        setMyOrders(userAsBuyerOrder);
        setBalance(balande.data);
      }
      if (auth.currentUser) {
        fetchOrders();
      }
    } catch (error) {
      console.log(error);
    }
  }, [auth.currentUser]);
  return (
    <div className="pt-20 mx-10 md:mx-20">
      {balanc.available && (
        <div className="flex justify-between">
          <div className="flex flex-col">
            <p>your balance :</p>
            <p> available for Withdrawl: {balanc?.available[0].amount} </p>
            <p> pending clearance: {balanc?.pending[0].amount / 100} </p>
          </div>
          <Button className="bg-blue-500">Withdrawl</Button>
        </div>
      )}

      <h1 className="text-3xl font-bold text-gray-600 dark:text-white">
        Orders
      </h1>
      <div className="currentOrders grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 grid-rows-1">
        {MyOrders ? (
          MyOrders.map((order) => {
            const id = order.id;
            order = order.data();
            order.id = id;
            return <OrdersComponent {...order} />;
          })
        ) : (
          <p>No orders</p>
        )}
      </div>
    </div>
  );
}

export default OrdersPage;
