import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    // if current route is /home. only then set small to false
      if (typeof window !== "undefined") {
        window.addEventListener("scroll", () => {
          if (window.location.pathname === "/") {
            setSmall(window.pageYOffset > 200);
          } else {
            setSmall(true);
          }
        });
      }
    
    return () => {
      window.removeEventListener("scroll", () =>
        setSmall(true)
      );
    };
  }, []);


  return (
    <nav
      className={`flex flex-wrap items-center justify-between px-2 py-3  fixed duration-500 top-0 w-full z-10 ${
        small
          ? "bg-background shadow-md text-text"
          : "bg-transparent text-white"
      }`}
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
          <a
            className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            href="/"
          >
            TravelTuk
          </a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
          >
            <span className="block relative w-6 h-px rounded-sm bg-white"></span>
            <span className="block relative w-6 h-px rounded-sm bg-white mt-1"></span>
            <span className="block relative w-6 h-px rounded-sm bg-white mt-1"></span>
          </button>
        </div>
        <div
          className="flex flex-grow items-center"
          id="example-navbar-warning"
        >
          <ul
            className={`lg:flex flex-col hidden lg:flex-row list-none mr-auto `}
          >
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  hover:opacity-75"
                href="/"
              >
                {/* <i className="fab fa-facebook-square text-lg leading-lg text-text opacity-75" /> */}
                <Link to="/">Home</Link>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  hover:opacity-75"
                href="/"
              >
                <span className="ml-2">
                  <Link to="/posts">Feed</Link>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  hover:opacity-75"
                href="/"
              >
                <span className="ml-2">
                  <Link to="/messages">Messages</Link>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  hover:opacity-75"
                href="/"
              >
                <span className="ml-2">
                  <Link to="/profile">Profile</Link>
                </span>
              </a>
            </li>
          </ul>
          <div className="relative hidden lg:flex w-full sm:w-7/12 md:w-5/12 px-4 flex-wrap items-stretch lg:ml-auto">
            <input
              type="text"
              className="px-4 py-1 h-8 border-none rounded-full text-sm leading-snug  bg-white/25 shadow-none outline-none focus:outline-none w-full font-normal flex-1 placeholder-primary"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
