import React from "react";
import { dummy } from "../../dummy";
import Gig from "../../Components/Gigs/Gig";
import Hero from "../../Components/Hero/Hero";
import FooterComp from "../../Components/Utility/Footer/FooterComp";
import { useEffect } from "react";
import { getfourtyGigs } from "../../Helpers/Controllers/gig.controller";
import { useState } from "react";
import { categoriesAndSubcategories } from "../../Helpers/constants";
import { auth } from "../../Helpers/FirebaseConfig";
import { categoriesWithImages } from "../../Settings/Texts";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const [gigs, setGigs] = useState([]);
  // const sections = ["Guide", "HotelRental", "VehicleRental", "HomeRental"];
  const sections = categoriesAndSubcategories.map((item) => {
    return item.category;
  });
  useEffect(() => {
    async function getSampleGigs() {
      setGigs(await getfourtyGigs());
      // console.log(res);
    }
    try {
      getSampleGigs();
    } catch (error) {
      console.log(error);
    }
  }, [auth.currentUser]);

  const navigate = useNavigate();

  
  return (
    <div className=" overflow-visible ">
      <Hero />
      <div className="pt-5 px-2 bg-secondary">
        <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 place-items-center gap-4">
          {categoriesAndSubcategories.map((category, i) => (
            <div
            onClick={() => {
              navigate({
                pathname: "/search",
                search: `?category=${category.category}`,
              });
            }}
              className="category-card relative border w-full text-gray-400 border-gray-400 col-span-1 truncate overflow-hidden rounded hover:shadow-xl duration-500 hover:-translate-y-6 cursor-pointer"
              key={i}
            >
              <img
                src={category.image}
                alt=""
                className="w-full h-40 object-cover"
              />
              <p className=" truncate text-center text-black/60 px-4 py-2 rounded text-xl font-bold hover:scale-110 duration-100">
                {category.category}
              </p>
              <button className="hover:bg-black absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 bg-black/40 transition-all duration-150 border-2 hover:border-black text-white hover:text-white px-4 py-2 rounded text-xl font-bold mt-4">
                Explore
              </button> 
            </div>
          ))}
        </div>
        <p className=" font-bold text-4xl text-black/70 text-center py-10">
          Explore Trending Categories
        </p>
      </div>
      {sections.map((section, i) => {
        return (
          <div key={i} className="gig-section w-full mt-20">
            <div className="flex justify-between items-center w-full px-5">
              <h1 className="text-3xl font-bold text-gray-800">{section}</h1>
              <p className=" text-sm font-thin text-blue-500">see all</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 overflow-x-scroll">
              {gigs
                .filter((a) => a.data().category === section)
                .map((item) => {
                  const id = item.id;
                  item = item.data();
                  item.id = id;
                  return <Gig key={id} {...item} />;
                })}
            </div>
          </div>
        );
      })}
      <FooterComp />
    </div>
  );
}

export default HomePage;
