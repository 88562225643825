import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';


// const firebaseConfig = {
//   apiKey: "AIzaSyA863d9ho5ZfhDqEusGIGiZ0w0KF2AorUk",
//   authDomain: "every-personal-projects-ever.firebaseapp.com",
//   projectId: "every-personal-projects-ever",
//   storageBucket: "every-personal-projects-ever.appspot.com",
//   messagingSenderId: "57345226882",
//   appId: "1:57345226882:web:71fbe22e426d6179d99c9b",
//   measurementId: "G-7J68R3NG9Z",
// };

const firebaseConfig = {

  apiKey: "AIzaSyArUqglSMMt2APU-N09AkilYznzVdcxz1I",

  authDomain: "travelapp-3fbe3.firebaseapp.com",

  projectId: "travelapp-3fbe3",

  storageBucket: "travelapp-3fbe3.appspot.com",

  messagingSenderId: "907368521584",

  appId: "1:907368521584:web:bc0b72a32ff9c757dcf9b5",

  measurementId: "G-LFCTFRVDJ9"

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // db
const functions = getFunctions(app); // functions

export { app, auth, db, functions };
