import React from 'react'

function ArrowButton({right}) {
    
  if(right){
    return (
        <i className="fa fa-arrow-right text-black"></i>
    )
  }else{
    return (
        <i className="fa fa-arrow-left text-black"></i>
    )
  }
}

export default ArrowButton