const navbarConst = {
    styles : {
        top: {
            position: 'fixed',
            width: '100%',
            zIndex: 1000,
            backgroundColor: '#fff',
            color: '#000',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)'
        },
        bottom: {
            position: 'fixed',
            width: '100%',
            zIndex: 1000,
            backgroundColor: 'transparent',
            color: '#fff',
            boxShadow: 'none'
        }
    }
}

const categoriesAndSubcategories = [
    {
        "category": "Guide",
        "image": "https://cdn.pixabay.com/photo/2013/03/03/15/49/mount-everest-89590_960_720.jpg",
        "subcategories": [
            "Tour Guide",
            "Travel Guide",
            "Food Guide",
            "Shopping Guide",
            "Nightlife Guide",
            "Museum Guide",
            "Historical Guide",
            "Art Guide",
            "Music Guide",
            "Sports Guide",
            "Outdoor Guide",
            "Other Guide"
        ]
    },
    {
        "category": "Vehicle",
        "image": "https://cdn.pixabay.com/photo/2020/06/25/17/58/mercedes-glc-5340358_960_720.jpg",
        "subcategories": [
            "Car",
            "Motorcycle",
            "Bicycle",
            "Boat",
            "Other Vehicle"
        ]
    },
    {
        "category": "Stay",
        "image": "https://cdn.pixabay.com/photo/2016/11/18/17/46/house-1836070__340.jpg",
        "subcategories": [
            "Hotel",
            "Hostel",
            "Home",
            "Bed and Breakfast",
            "Camping",
            "Other Stay"
        ]
    },
    {
        "category": "Food",
        "image": "https://cdn.pixabay.com/photo/2018/05/01/18/21/eclair-3366430_960_720.jpg",
        "subcategories": [
            "Restraunts",
            "Drinks",
            "Desserts",
            "Local Food",
            "Other"
        ]
    },
    {
        "category": "Agency",
        "image": "https://www.nutanix.com/content/dam/nutanix-newsroom/cloud-based-travel-agency-technology/img-digital-travel-agent-thumb.jpg",
        "subcategories": [
            "Tour Agency",
            "Travel Agency",
            "Food Agency",
            "Other Agency"
        ]
    },
    {
        "category": "Entertainment",
        "image": "https://cdn.pixabay.com/photo/2016/08/05/21/47/lightshow-1573327_960_720.jpg",
        "subcategories": [
            "Nightlife",
            "Museum",
            "Historical",
            "Art",
            "Music",
            "Sports",
            "Club",
            "Outdoor",
            "Other Entertainment"
        ]
    },
    {
        "category": "Visa",
        "image": "https://cdn.pixabay.com/photo/2017/08/14/22/06/passport-2642172__340.jpg",
        "subcategories": [
            "Visa",
            "Other Visa"
        ]
    },
    {
        "category": "Ticketing",
        "image": "https://cdn.pixabay.com/photo/2017/08/08/00/37/still-2609682_960_720.jpg",
        "subcategories": [
            "Bus Ticket",
            "Train Ticket",
            "Plane Ticket",
            "Cruise Ticket",
            "Other Ticket"
        ]
    },
    {
        "category": "Adventure",
        "image": "https://cdn.pixabay.com/photo/2013/02/15/02/22/skydivers-81778_960_720.jpg",
        "subcategories": [
            "Hiking",
            "Climbing",
            "Caving",
            "Canyoning",
            "Rafting",
            "Bungee Jumping",
            "Paragliding",
            "Skydiving",
            "Other Adventure"
        ]
    },
    {
        "category": "Other",
        "image": "https://cdn.pixabay.com/photo/2016/10/18/09/02/hotel-1749602__340.jpg",
        "subcategories": [
            "Other"
        ]
    }
]
export {navbarConst, categoriesAndSubcategories};