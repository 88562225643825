import { httpsCallable } from "firebase/functions";
import { Card, Spinner } from "flowbite-react";
import { useState } from "react";
import { auth, functions } from "../../Helpers/FirebaseConfig";
import { getDateFromTimestamp } from "../../Helpers/utilityFunctions";
import { createCheckoutSession } from "../../Stripe/createCheckoutSession";
import { markOrderCompleted } from "../../Stripe/markOrderCompleted";

const OrdersComponent = ({
  id,
  sellerName,
  buyerName,
  gigTitle,
  gigId,
  buyerId,
  sellerId,
  sellerAvatar,
  BuyerAvatar,
  description,
  startDate,
  deadline,
  price,
  status,
  messageId,
  messageRoomId,
}) => {
  // console.log(id, sellerName, buyerName, gigTitle, gigId, buyerId, sellerId, sellerAvatar, BuyerAvatar, description, startDate, deadline, price, status, messageId, messageRoomId)
  const [loading, setLoading] = useState(false);

  const userSideCta = {
    pending: {
      action: async () => {
        setLoading(true);
        const res = await createCheckoutSession({
          sellerId,
          buyerId,
          gigId,
          orderId: id,
          messageId,
          messageRoomId,
        });
        setLoading(false);
        window.location.href = res.data.url;
      },
      element: <button className="btn btn-primary">Approve</button>,
    },
    approved: {
      action: [
        async () => {
          setLoading(true);
          const res = await createCheckoutSession({
            sellerId,
            buyerId,
            gigId,
            orderId: id,
            messageId,
            messageRoomId,
          });
          setLoading(false);
          window.location.href = res.data.url;
        },
      ],
      element: (
        <div className="flex">
          <button className="btn btn-primary">Mark Completed</button>
          <button className="btn btn-danger">Cancel</button>
        </div>
      ),
    },
  };

  return (
    <div className="max-w-sm">
      <Card>
        <div className="flex justify-between mb-0">
          <p className="text-sm text-gray-500">
            Start Date: {getDateFromTimestamp(startDate?.seconds || Date.now())}
          </p>
          <p className="text-sm text-gray-500">Deadline: {deadline}</p>
        </div>
        <a href={`/gigs?id=${gigId}`}>
          <h5 className="text-xl font-semibold text-gray-900 dark:text-white">
            {gigTitle}
          </h5>
          <h5 className="text-md  text-gray-600 dark:text-white">
            {description}
          </h5>
        </a>
        <div className="mt-2.5 mb-5 flex items-center">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {sellerName}
            {" > "}
            {buyerName}
          </p>

          <span className="mr-2 ml-3 rounded bg-blue-100 px-2.5 py-0.5 text-xs font-semibold text-blue-800 dark:bg-blue-200 dark:text-blue-800">
            {status}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-3xl font-bold text-gray-900 dark:text-white">
            ${price}
          </span>
          {status === "pending" && sellerId !== auth.currentUser?.uid ? (
            <p
              onClick={async () => {
                setLoading(true);
                const sid = await createCheckoutSession({
                  id,
                  gigId,
                  gigTitle,
                  sellerId,
                  sellerName,
                  sellerAvatar,
                  buyerId,
                  buyerName,
                  BuyerAvatar,
                  description,
                  startDate,
                  deadline,
                  price,
                  status,
                  messageId,
                  messageRoomId,
                });
                console.log(sid);

                setLoading(false);
              }}
              className="rounded-lg cursor-pointer bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {loading ? <Spinner /> : "Accept Offer"}
            </p>
          ) : status === "approved" && buyerId === auth.currentUser?.uid ? (
            <p
              onClick={async () => {
                setLoading(true);
                const ord = await markOrderCompleted({orderId: id})
                console.log(ord);
                setLoading(false);
              }}
              className="rounded-lg cursor-pointer bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {loading ? <Spinner /> : "Mark Completed"}
            </p>
          ) : (
            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
              {status}
            </p>
          )}
        </div>
      </Card>
    </div>
  );
};



export default OrdersComponent;
