const dummy = [
  {
    title: "Machu Picchu Guide",
    description:
      "I am a tour guide for Machu Picchu. I have been guiding tourists for over 10 years and know the area very well. I can help you plan your trip and make the most of your time here.",
    price: 100,
    category: "Guide",
    subCategory: "Traveller",
    location: "Machu Picchu, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.5,
  },
  {
    title: "Home Rental in Cusco",
    description:
      "I have a 3 bedroom home available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 500,
    category: "HomeRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.8,
  },
  {
    title: "Hotel Rental in Cusco",
    description:
      "I have a 3 bedroom hotel available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 1000,
    category: "HotelRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 5,
  },
  {
    title: "Vehicle Rental in Cusco",
    description:
      "I have a 3 bedroom vehicle available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 2000,
    category: "VehicleRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.7,
  },
  {
    title: "Machu Picchu Guide",
    description:
      "I am a tour guide for Machu Picchu. I have been guiding tourists for over 10 years and know the area very well. I can help you plan your trip and make the most of your time here.",
    price: 100,
    category: "Guide",
    location: "Machu Picchu, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.5,
  },
  {
    title: "Home Rental in Cusco",
    description:
      "I have a 3 bedroom home available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 500,
    category: "HomeRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.8,
  },
  {
    title: "Hotel Rental in Cusco",
    description:
      "I have a 3 bedroom hotel available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 1000,
    category: "HotelRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 5,
  },
  {
    title: "Vehicle Rental in Cusco",
    description:
      "I have a 3 bedroom vehicle available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 2000,
    category: "VehicleRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.7,
  },
  {
    title: "Machu Picchu Guide",
    description:
      "I am a tour guide for Machu Picchu. I have been guiding tourists for over 10 years and know the area very well. I can help you plan your trip and make the most of your time here.",
    price: 100,
    category: "Guide",
    location: "Machu Picchu, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.5,
  },
  {
    title: "Home Rental in Cusco",
    description:
      "I have a 3 bedroom home available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 500,
    category: "HomeRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.8,
  },
  {
    title: "Hotel Rental in Cusco",
    description:
      "I have a 3 bedroom hotel available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 1000,
    category: "HotelRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 5,
  },
  {
    title: "Vehicle Rental in Cusco",
    description:
      "I have a 3 bedroom vehicle available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 2000,
    category: "VehicleRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.7,
  },
  {
    title: "Machu Picchu Guide",
    description:
      "I am a tour guide for Machu Picchu. I have been guiding tourists for over 10 years and know the area very well. I can help you plan your trip and make the most of your time here.",
    price: 100,
    category: "Guide",
    location: "Machu Picchu, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.5,
  },
  {
    title: "Home Rental in Cusco",
    description:
      "I have a 3 bedroom home available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 500,
    category: "HomeRental",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    rating: 4.8,
  },
  {
    title: "Hotel Rental in Cusco",
    description:
      "I have a 3 bedroom hotel available for rent in Cusco. It is fully furnished and includes all utilities. It is centrally located and close to all the best restaurants and attractions.",
    price: 1000,
    category: "HotelRental",
    subCategory: "5-star-hotel",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855555",
    rating: 5,
  },
];

const dummyPost = [
  {
    title: "Had a blast in Cusco",
    category: "HotelRental",
    subCategory: "5-star hotel",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2022/09/11/14/52/bee-7446944_960_720.jpg",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    sellerName: "John Doe",
    profilePicUrl:
      "https://cdn.pixabay.com/photo/2016/12/21/00/36/woman-1921883_960_720.jpg",
    profileUrl: "https://www.google.com",

    rating: 5,
    likesCount: 52,
    datePosted: "2020-08-20T20:00:00.000Z",
    comments: [
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
    ],
  },
  {
    title: "Had a blast in Cusco",
    category: "HotelRental",
    subCategory: "5-star hotel",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    sellerName: "John Doe",
    profilePicUrl:
      "https://cdn.pixabay.com/photo/2016/12/21/00/36/woman-1921883_960_720.jpg",
    profileUrl: "https://www.google.com",

    rating: 5,
    likesCount: 52,
    datePosted: "2020-08-20T20:00:00.000Z",
    comments: [
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
    ],
  },
  {
    title: "Had a blast in Cusco",
    category: "HotelRental",
    subCategory: "5-star hotel",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    sellerName: "John Doe",
    profilePicUrl:
      "https://cdn.pixabay.com/photo/2016/12/21/00/36/woman-1921883_960_720.jpg",
    profileUrl: "https://www.google.com",

    rating: 5,
    likesCount: 52,
    datePosted: "2020-08-20T20:00:00.000Z",
    comments: [
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
    ],
  },
  {
    title: "Had a blast in Cusco",
    category: "HotelRental",
    subCategory: "5-star hotel",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    sellerName: "John Doe",
    profilePicUrl:
      "https://cdn.pixabay.com/photo/2016/12/21/00/36/woman-1921883_960_720.jpg",
    profileUrl: "https://www.google.com",

    rating: 5,
    likesCount: 52,
    datePosted: "2020-08-20T20:00:00.000Z",
    comments: [
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
    ],
  },
  {
    title: "Had a blast in Cusco",
    category: "HotelRental",
    subCategory: "5-star hotel",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    sellerName: "John Doe",
    profilePicUrl:
      "https://cdn.pixabay.com/photo/2016/12/21/00/36/woman-1921883_960_720.jpg",
    profileUrl: "https://www.google.com",

    rating: 5,
    likesCount: 52,
    datePosted: "2020-08-20T20:00:00.000Z",
    comments: [
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        comment: "This is a great post!",
      },
    ],
  },
  {
    title: "Had a blast in Cusco",
    category: "HotelRental",
    subCategory: "5-star hotel",
    location: "Cusco, Peru",
    images: [
      "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
      "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
      "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
    ],
    seller: "5f3b835a4f7e1a0b59855a09",
    sellerName: "John Doe",
    profilePicUrl:
      "https://cdn.pixabay.com/photo/2016/08/01/20/15/girl-1562025_960_720.jpg",
    profileUrl: "https://www.google.com",

    rating: 5,
    likesCount: 52,
    datePosted: "2020-08-20T20:00:00.000Z",
    comments: [
      {
        user: "5f3b835a4f7e1a0b59855a09",
        name: "John Doe",
        profilePicUrl: "https://cdn.pixabay.com/photo/2016",
        time: "2020-08-20T20:00:00.000Z",
        location: "Cusco, Peru",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        name: "John Doe",
        profilePicUrl: "https://cdn.pixabay.com/photo/2016",
        time: "2020-08-20T20:00:00.000Z",
        location: "Cusco, Peru",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        name: "John Doe",
        profilePicUrl: "https://cdn.pixabay.com/photo/2016",
        time: "2020-08-20T20:00:00.000Z",
        location: "Cusco, Peru",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        name: "John Doe",
        profilePicUrl: "https://cdn.pixabay.com/photo/2016",
        time: "2020-08-20T20:00:00.000Z",
        location: "Cusco, Peru",
        comment: "This is a great post!",
      },
      {
        user: "5f3b835a4f7e1a0b59855a09",
        name: "John Doe",
        profilePicUrl: "https://cdn.pixabay.com/photo/2016",
        time: "2020-08-20T20:00:00.000Z",
        location: "Cusco, Peru",
        comment: "This is a great post!",
      },
    ],
  },
];

const dummyComments = [
  {
    user: "5f3b835a4f7e1a0b59855a09",
    name: "John Doe",
    profilePicUrl: "https://cdn.pixabay.com/photo/2016",
    time: "2020-08-20T20:00:00.000Z",
    location: "Cusco, Peru",
    comment: "This is a great post!",
  },
];

const dummyGig = {
  title: "I Will be your Guide in Cusco",
  description: `
  Looking to explore the city of Los Angeles, but not sure where to start? Hire a local guide! Los Angeles is a huge metropolis with a lot to see and do, and a professional guide can help you make the most of your trip.
  
  A good guide will show you the must-see sights, as well as some of the hidden gems that you might not find on your own. They can also help you navigate the city, avoid tourist traps, and find the best places to eat and drink.
  
  Ready to start planning your trip? Hire a local guide and start exploring all that Los Angeles has to offer!`,
  category: "HotelRental",
  subCategory: "5-star hotel",
  location: "Cusco, Peru",
  images: [
    "https://cdn.pixabay.com/photo/2017/12/15/13/51/polynesia-3021072_960_720.jpg",
    "https://cdn.pixabay.com/photo/2016/03/28/09/34/bedroom-1285156__340.jpg",
    "https://cdn.pixabay.com/photo/2020/10/18/09/16/bedroom-5664221__340.jpg",
    "https://cdn.pixabay.com/photo/2015/01/10/11/39/hotel-595121__340.jpg",
  ],
  seller: "5f3b835a4f7e1a0b59855a09",
  sellerName: "John Doe",
  profilePicUrl: "https://cdn.pixabay.com/photo/2016/12/21",
  profileUrl: "https://www.google.com",
  prices: [
    {
      desc: `In this Basic pacakge, you will get: 

      -A 2-hour private walking tour of Downtown Los Angeles with a professional guide
      -Customizable itinerary based on your interests
      -Insider tips on the best places to eat, drink, and shop`,
      price: "$100",
    },
    {
      desc: "usual hotel",
      price: "$1000",
    },
    {
      desc: "very usual hotel",
      price: "$10000",
    },
  ],
  rating: 4,
  reviews: [
    {
      rating: 5,
      reviewerName: "John Doe",
      review: "This is a great post!",
      reviewerId: "5f3b835a4f7e1a0b59855a09",
      reviewerProfilePicUrl: "https://cdn.pixabay.com/photo",
      orderId: "aslfmkdandnv",
      reviewDate: "22-01-2021",
    },
    {
      rating: 5,
      reviewerName: "John Doe",
      review: "This is a great post!",
      reviewerId: "5f3b835a4f7e1a0b59855a09",
      reviewerProfilePicUrl: "https://cdn.pixabay.com/photo",
      orderId: "aslfmkdandnv",
      reviewDate: "22-01-2021",
    },
    {
      rating: 5,
      reviewerName: "John Doe",
      review: "This is a great post!",
      reviewerId: "5f3b835a4f7e1a0b59855a09",
      reviewerProfilePicUrl: "https://cdn.pixabay.com/photo",
      orderId: "aslfmkdandnv",
      reviewDate: "22-01-2021",
    },
  ],
};

const dummyProfile = {
  name: "John Doe",
  profilePicUrl: "https://cdn.pixabay.com/photo/2016/09/28/08/28/art-1699977_960_720.jpg",
  profileUrl: "https://www.google.com",
  location: "Cusco, Peru",
  bio: "I am a professional guide in Cusco",
  joined: "2020-08-20T20:00:00.000Z",

  gigs: [
    {
      title: "Machu Picchu Guide",
      description:
        "I am a tour guide for Machu Picchu. I have been guiding tourists for over 10 years and know the area very well. I can help you plan your trip and make the most of your time here.",
      price: 100,
      category: "Guide",
      subCategory: "Traveller",
      location: "Machu Picchu, Peru",
      images: [
        "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
        "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
        "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
      ],
      seller: "5f3b835a4f7e1a0b59855a09",
      rating: 4.5,
    },
    {
      title: "Machu Picchu Guide",
      description:
        "I am a tour guide for Machu Picchu. I have been guiding tourists for over 10 years and know the area very well. I can help you plan your trip and make the most of your time here.",
      price: 100,
      category: "Guide",
      subCategory: "Traveller",
      location: "Machu Picchu, Peru",
      images: [
        "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
        "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
        "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
      ],
      seller: "5f3b835a4f7e1a0b59855a09",
      rating: 4.5,
    },
    {
      title: "Machu Picchu Guide",
      description:
        "I am a tour guide for Machu Picchu. I have been guiding tourists for over 10 years and know the area very well. I can help you plan your trip and make the most of your time here.",
      price: 100,
      category: "Guide",
      subCategory: "Traveller",
      location: "Machu Picchu, Peru",
      images: [
        "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
        "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
        "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
      ],
      seller: "5f3b835a4f7e1a0b59855a09",
      rating: 4.5,
    },
  ],
  posts: [
    {
      title: "Had a blast in Cusco",
      category: "HotelRental",
      subCategory: "5-star hotel",
      location: "Cusco, Peru",
      images: [
        "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
        "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
        "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
      ],
      seller: "5f3b835a4f7e1a0b59855a09",
      sellerName: "John Doe",
      profilePicUrl:
        "https://cdn.pixabay.com/photo/2016/08/01/20/15/girl-1562025_960_720.jpg",
      profileUrl: "https://www.google.com",

      rating: 5,
      likesCount: 52,
      datePosted: "2020-08-20T20:00:00.000Z",
      comments: [
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
      ],
    },
    {
      title: "Had a blast in Cusco",
      category: "HotelRental",
      subCategory: "5-star hotel",
      location: "Cusco, Peru",
      images: [
        "https://cdn.pixabay.com/photo/2017/06/20/08/44/cusco-2422267__340.png",
        "https://cdn.pixabay.com/photo/2020/10/27/08/00/mountains-5689938_960_720.png",
        "https://cdn.pixabay.com/photo/2013/10/02/23/03/mountains-190055__340.jpg",
      ],
      seller: "5f3b835a4f7e1a0b59855a09",
      sellerName: "John Doe",
      profilePicUrl:
        "https://cdn.pixabay.com/photo/2016/08/01/20/15/girl-1562025_960_720.jpg",
      profileUrl: "https://www.google.com",

      rating: 5,
      likesCount: 52,
      datePosted: "2020-08-20T20:00:00.000Z",
      comments: [
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
        {
          user: "5f3b835a4f7e1a0b59855a09",
          name: "John Doe",
          profilePicUrl: "https://cdn.pixabay.com/photo/2016",
          time: "2020-08-20T20:00:00.000Z",
          location: "Cusco, Peru",
          comment: "This is a great post!",
        },
      ],
    },
  ],
  reviews: [
    {
      rating: 5,
      reviewerName: "John Doe",
      review: "This is a great post!",
      reviewerId: "5f3b835a4f7e1a0b59855a09",
      reviewerProfilePicUrl: "https://cdn.pixabay.com/photo",
      orderId: "aslfmkdandnv",
      reviewDate: "22-01-2021",
    },
    {
      rating: 5,
      reviewerName: "John Doe",
      review: "This is a great post!",
      reviewerId: "5f3b835a4f7e1a0b59855a09",
      reviewerProfilePicUrl: "https://cdn.pixabay.com/photo",
      orderId: "aslfmkdandnv",
      reviewDate: "22-01-2021",
    },
  ],
};

export { dummy, dummyPost, dummyGig, dummyProfile };
