import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Gig from "../../Components/Gigs/Gig";
import { dummy } from "../../dummy";
import { getSavedGigs } from "../../Helpers/Controllers/savedGIg.controller";
import { auth } from "../../Helpers/FirebaseConfig";

function SavedGigsPage() {
  const [savedGigs, setSavedGigs] = useState([]);
  useEffect(() => {
    try {
      async function getSavedgigsForUser() {
        const sg = await getSavedGigs({userId: auth.currentUser?.uid});
        setSavedGigs(sg);
      }
      getSavedgigsForUser();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div className="pt-20 px-20">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {savedGigs ? (
          savedGigs.map((item) => {
            const id = item.id;
            item = item.data();
            item.id = id;
            return <Gig key={item.id} {...item} />;
          })
        ) : (
          <div className="grid h-screen place-items-center text-6xl text-gray-500 font-extrabold">
            No Saved Gigs yet
          </div>
        )}
      </div>
    </div>
  );
}

export default SavedGigsPage;
