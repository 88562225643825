import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  approveVerification,
  getVerificationRequests,
  rejectVerification,
} from "../../Helpers/Controllers/admin.controller";
import { fetchOrdersByStatus } from "../../Helpers/Controllers/orders.controller";
import {
  blockUser,
  deleteAccount,
  fetchBlockedUsers,
  getUserByEmailAddress,
  unblockUser,
} from "../../Helpers/Controllers/user.controller";

function AdminPage() {
  return (
    <div className=" pt-20 ">
      <VerificationSection />
      <PendingOrderSection />
      <CompletedOrderSection />
      <UserSearch />
      <BlockedUsers />
    </div>
  );
}

const VerificationSection = () => {
  const [verificationReqs, setVerificationReqs] = useState([]);
  const [loading, setLoading] = useState("");
  useEffect(() => {
    try {
      async function getVerificationReqs() {
        const response = await getVerificationRequests();
        setVerificationReqs(response);
      }
      getVerificationReqs();
    } catch (error) {
      alert(error.message);
    }
  }, []);
  return (
    <div className="verification-section">
      <p className=" text-4xl">Verification Requests</p>
      <div className="flex">
        {verificationReqs.map((req) => (
          <div className="w-60 border-2 p-2 m-2 rounded-lg">
            <img
              src={req.data().verification}
              onClick={() => {
                window.open(req.data().verification);
              }}
              alt="cant read"
            />
            <p>{req.data().name}</p>
            <p>{req.data().email}</p>
            <div className="flex">
              <button
                onClick={async () => {
                  await approveVerification(req.data());
                  const newReqs = verificationReqs.filter(
                    (item) => item.id !== req.id
                  );
                  setVerificationReqs(newReqs);
                }}
                className=" bg-lime-500 text-white font-bold p-2 rounded-lg m-2"
              >
                Approve
              </button>
              <button
                onClick={async () => await rejectVerification()}
                className=" bg-red-700 text-white font-bold p-2 rounded-lg m-2"
              >
                Reject
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PendingOrderSection = () => {
  const [pendingOrders, setPendingOrders] = useState([]);
  useEffect(() => {
    async function getPendingOrders() {
      const response = await fetchOrdersByStatus({ status: "approved" });
      console.log(response);
      setPendingOrders(response);
    }
    getPendingOrders();
  }, []);
  return (
    <div className="pending-order-section">
      <p className=" text-4xl">Pending Orders</p>
      <div className="flex">
        {pendingOrders &&
          pendingOrders.map((order) => {
            // order = order.data();
            return (
              <div className="w-60 border-2 p-2 m-2 rounded-lg">
                <div className="w-60 border-2 p-2 m-2 rounded-lg">
                  <p>title: {order.data().gigTitle}</p>
                  <p>price : {order.data().gigPrice}</p>
                  <p>paidBy : {order.data().paidBy}</p>
                  <p>SellerName : {order.data().sellerName}</p>
                  <p>BuyerName : {order.data().BuyerName}</p>
                  <p>BuyerEmail : {order.data().BuyerEmail}</p>
                  <p>Started At : {order.data().created}</p>
                  <p>deadline : {order.data().deadline}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const CompletedOrderSection = () => {
  const [completedOrders, setCompletedOrders] = useState([]);
  useEffect(() => {
    async function getCompletedOrders() {
      const response = await fetchOrdersByStatus({ status: "paid" });
      setCompletedOrders(response);
    }
    getCompletedOrders();
  }, []);
  return (
    <div className="completed-order-section">
      <p className=" text-4xl">Completed Orders</p>
      <div className="flex">
        {completedOrders &&
          completedOrders.map((order) => {
            // order = order.data();
            // console.log(order.data());
            return (
              <div className="w-60 border-2 p-2 m-2 rounded-lg">
                <p>title: {order.data().gigTitle}</p>
                <p>price : {order.data().gigPrice}</p>
                <p>paidBy : {order.data().paidBy}</p>
                <p>SellerName : {order.data().sellerName}</p>
                <p>BuyerName : {order.data().BuyerName}</p>
                <p>BuyerEmail : {order.data().BuyerEmail}</p>
                <p>Started At : {order.data().created}</p>
                <p>deadline : {order.data().deadline}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const UserSearch = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  useEffect(() => {}, []);
  return (
    <div className="user-search-section">
      <p className=" text-4xl">Search Users</p>
      <div className="flex">
        <input
          type="text"
          placeholder="Search email"
          className="border-2 p-2 m-2 rounded-lg"
          onChange={(e) => setSearch(e.target.value)}
        />
        <button
          onClick={async () => {
            setLoading(true);
            const response = await getUserByEmailAddress(search);
            console.log(response.docs);
            setUsers(response.docs);
            setLoading(false);
          }}
          className="bg-lime-500 text-white font-bold p-2 rounded-lg m-2"
        >
          Search
        </button>
      </div>
      <div className="flex">
        {loading && <p>Loading...</p>}
        {users &&
          users.map((user) => (
            <div className="w-60 border-2 p-2 m-2 rounded-lg">
              <img src={user.data().photoURL} alt="cant read" />
              <p>{user.data().name}</p>
              <p>{user.data().email}</p>
              <p>{Date(user.data().createdAt)}</p>

              <p>{user.data().verified}</p>
              <div className="flex">
                <button
                  onClick={async () => {
                    await deleteAccount(user.data().id);
                    const newUsers = users.filter(
                      (item) => item.id !== user.id
                    );
                    setUsers(newUsers);
                  }}
                  className=" bg-red-700 text-white font-bold p-2 rounded-lg m-2"
                >
                  Delete
                </button>
                <button
                  onClick={async () => {
                    console.log(user.data());
                    await blockUser(user.data().uid);
                    const newUsers = users.filter(
                      (item) => item.id !== user.id
                    );
                    setUsers(newUsers);
                  }}
                  className=" bg-red-700 text-white font-bold p-2 rounded-lg m-2"
                >
                  {user.blocked ? "AlreadyBlocked" : "Block"}
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};


const BlockedUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getBlockedUsers() {
      const response = await fetchBlockedUsers();
      setUsers(response.docs);
    }
    getBlockedUsers();
  }, []);
  return (
    <div className="user-search-section">
      <p className=" text-4xl">Blocked Users</p>
      <div className="flex">
        {users &&
          users.map((user) => (
            <div className="w-60 border-2 p-2 m-2 rounded-lg">
              <img src={user.data().photoURL} alt="cant read" />
              <p>{user.data().name}</p>
              <p>{user.data().email}</p>
              <p>{Date(user.data().createdAt)}</p>
              {/* <p>{user.data().verified}</p> */}
              <div className="flex">
                <button
                  onClick={async () => {
                    await unblockUser(user.data().uid);
                    const newUsers = users.filter(
                      (item) => item.uid !== user.uid
                    );
                    setUsers(newUsers || []);
                  }}
                  className=" bg-red-700 text-white font-bold p-2 rounded-lg m-2"
                >
                  Unblock
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdminPage;
