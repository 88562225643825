import { Avatar } from "flowbite-react";
import React from "react";

function ProfilePic({ url }) {
  return url ? (
    <div className="mr-1">
      <Avatar img={url} rounded={true} />
    </div>
  ) : (
    <Avatar
      alt="User settings"
      img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
      rounded={true}
    />
  );
}

export default ProfilePic;
