import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { categoriesAndSubcategories } from "../../Helpers/constants";
import { categoriesWithImages } from "../../Settings/Texts";

function GigSearch() {
  const [category, setCategory] = useState(categoriesWithImages);
  const navigate = useNavigate();
  const [locationText, setLocationText] = useState("");
  const gotoSearchPage = (e) => {
    e.preventDefault();
    const category = document.getElementById("categories-hero").value;
    let query = "?";
    query += `location=${locationText}`;
    if (category !== "All Categories") {
      query += `&category=${category}`;
    }

    navigate({
      pathname: "/search",

      search: query,
    });
  };
  return (
    <form onSubmit={e => gotoSearchPage(e)} className="grid grid-cols-8 gap-1 mx-2">
      <input
        type="text"
        value={locationText}
        onChange={(e) => setLocationText(e.target.value)}
        placeholder="Search locations"
        className="col-span-4 h-12 rounded px-4 text-xl focus:outline-none"
      />
      <select
        id="categories-hero"
        className="col-span-4 md:col-span-2 h-12 rounded px-4 text-xl focus:outline-none font-thin"
      >
        {categoriesAndSubcategories.map((cat, i) => (
          <option key={i} value={cat.category}>
            {cat.category}
          </option>
        ))}
      </select>
      <button
        type="submit"
        onClick={(e) => gotoSearchPage(e)}
        className="bg-black col-span-8 md:col-span-2 text-white px-4 py-2 rounded text-xl font-bold hover:scale-110 duration-100"
      >
        Search
      </button>
    </form>
  );
}

export default GigSearch;
