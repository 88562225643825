import React from 'react'

function Review({review}) {
  return (
    <div className="flex flex-col border-2 rounded items-center justify-center mr-4">
        <div className="p-4 flex flex-col rounded items-center justify-center">
          <div className="w-14 h-14 rounded-[50%] overflow-hidden mr-4">
            <img
              src={review.reviewerProfilePicUrl}
              className="w-full h-full object-cover"
              alt=""
            />
          </div>
          <div className="font-bold">{review.reviewerName}</div>
          <div className="font-light">{review.reviewDate}</div>

          <div className="font-light">{review.review}</div>
        </div>
        <div className="flex justify-center border w-full py-2">
          {[...Array(review.rating)].map((e, i) => (
            <i key={i} className="fa fa-star text-yellow-400"></i>
          ))}
          {[...Array(5 - review.rating)].map((e, i) => (
            <i key={i} className="fa fa-star text-gray-400"></i>
          ))}
        </div>
      </div>
  )
}

export default Review